import "../../styles/Home.css";
import React from "react";
import { Col, Spinner, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setCurrentDecision } from "../../redux/actions/data";
import { useHistory } from "react-router";
import HomeView from "../views/HomeView";
import { getSubUnits, getFilteredDecisions } from "../../functions/filterData";

function HomeContainer({
  decisions,
  information,
  loading,
  setCurrentDecision,
  units,
  categories,
}) {
  const history = useHistory();

  const userID = JSON.parse(window.localStorage.getItem("UserID"));

  const [category, setCategory] = React.useState("");

  const handleChange = (event, setFunc) => {
    setFunc(event);
  };

  let temo = [...units];
  console.log(temo.push({ id: 102 }));

  let loggedInID = userID;

  //Get subunits from loggedinID
  let subUnits = loggedInID && units ? getSubUnits(loggedInID, units) : null;

  //Sort decisions for "Recent decisions"
  const getSortedDecisions = () => {
    const tmp = getFilteredDecisions(decisions, subUnits);
    return tmp.sort(
      (a, b) => new Date(b.decisionDate) - new Date(a.decisionDate)
    );
  };

  //Get ongoing decisions
  const getOngoingDecisions = () => {
    const tmp = getFilteredDecisions(decisions, subUnits);
    return tmp.filter((d) => new Date(d.validTo) > new Date() || !d.validTo);
  };

  const getSortedDecisionsByCategory = () => {
    const tmp = getFilteredDecisions(decisions, subUnits);
    console.log(category.name);
    console.log(tmp.filter((key) => key.category.name === toString(category)));
    return tmp.filter((key) => key.category.name === category.name);
  };
  const chosecat = () => {
    return (
      <Form.Group as={Row}>
        <Form.Label id="formLabel" column sm={1}>
          Category:
        </Form.Label>
        <Col sm={1}>
          <Form.Control
            size="sm"
            as="select"
            value={category.name}
            onChange={(e) => {
              handleChange(
                {
                  name: e.target.value,
                  id: e.target.options[
                    e.target.options.selectedIndex
                  ].getAttribute("data-key"),
                },
                setCategory
              );
            }}
          >
            <option>Choose category</option>
            {categories.map((elem) => (
              <option key={elem.id} data-key={elem.id}>
                {elem.category}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };
  //console.log(decisions.filter((key) => key.category.name == "Politics"));
  // TODO change from demodata
  const demoData = [];
  const decisionUnits = [
    { header: "Recent Decisions", data: getSortedDecisions() },
    { header: "Ongoing Decisions", data: getOngoingDecisions() },
    {
      header: chosecat(),
      data: getSortedDecisionsByCategory(),
    },
    { header: "Recent Actions", data: demoData },
    { header: "Ongoing Actions", data: demoData },
    { header: "Matters", data: demoData },
    { header: "Meetings", data: demoData },
    { header: "Policies", data: demoData },
    { header: "Directions", data: demoData },
  ];
  const showDecision = (id) => {
    setCurrentDecision(id);
    history.push("/decisions/");
  };

  return (
    <Col id="mainviewcol">
      {loading || !information ? (
        <Col md={12} className="d-flex justify-content-center">
          <Spinner animation="border" />
        </Col>
      ) : (
        <HomeView
          decisions={decisions}
          information={information}
          showDecision={showDecision}
          loading={loading}
          decisionUnits={decisionUnits}
        />
      )}
    </Col>
  );
}

export default connect(
  (state) => {
    return {
      decisions: state.data.decisions,
      information: state.data.information,
      loading: state.data.loading,
      units: state.data.units,
      categories: state.data.categories,
    };
  },
  (dispatch) => {
    return {
      setCurrentDecision: (id) => dispatch(setCurrentDecision(id)),
    };
  }
)(HomeContainer);
