//Returns all subunits based on loggedinID
const getSub = (id, units) => {
  let tmp = units ? [...units] : [];
  let vari = tmp.filter((key) => key.superUnitID === id);
  let keys = [id.toString(), ...vari.map((key) => key.id)];
  return keys;
};
export const getSubUnits = (id, units) => {
  let subUnits = getSub(id, units);
  let ret = subUnits;
  for (var i = 1; i < subUnits.length; i++) {
    if (getSub(subUnits[i], units).length > 1) {
      let le = getSubUnits(subUnits[i], units).slice(1);
      ret = ret.concat(le);
    }
  }
  return ret;
};

export const getFilteredSubUnits = (units, subUnits) => {
  //console.log(subUnits);
  const tmp = units ? [...units] : [];
  if (subUnits === null) {
    return [];
  }

  const filteredUnits = tmp.filter((key) => checkId(key.id, subUnits));
  const ret = filteredUnits;
  return ret;
};

const checkId = (key, id) => {
  for (var i = 0; i < id.length; i++) {
    if (key === id[i]) {
      return true;
    }
  }
  return false;
};
//Filtering out based on loggedin user
export const getFilteredDecisions = (decisions, subUnits, sortBy) => {
  console.log(sortBy);
  const tmp = decisions ? [...decisions] : [];
  if (subUnits === null) {
    return [];
  }

  const filteredDecisions = tmp.filter((key) =>
    checkId(key.ownerUnit.id, subUnits)
  );
  const publicDecisions = getPublicDecisions(decisions);
  let ret = arrayUnique(publicDecisions.concat(filteredDecisions));

  if (sortBy == "name") {
    return ret.sort((a, b) => (a.title > b.title ? 1 : -1));
  }
  if (sortBy == "category") {
    console.log(
      ret.sort((a, b) => (a.category.name > b.category.name ? 1 : -1))
    );
    return ret.sort((a, b) => (a.category.name > b.category.name ? 1 : -1));
  }
  if (sortBy == "matter") {
    console.log(
      ret.sort((a, b) => (a.category.name > b.category.name ? 1 : -1))
    );
    return ret.sort((a, b) => (a.matter.name > b.matter.name ? 1 : -1));
  }
  return ret;
};

export const getPublicDecisions = (decisions) => {
  const tmp = decisions ? [...decisions] : [];
  return tmp.filter((key) => key.isPublic);
};

function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

export const formatStringExtract = (string, numberOfWords) => {
  if (string.length > 170) {
    return string.substring(0, 170) + "...";
  } else {
    return string;
  }
};

export const getSuperUnitID = (units, id) => {
  if (id == null || "") {
    return "No upper unit";
  } else {
    const tmp = units ? [...units] : [];
    const tempo = units.filter((key) => key.id == id);
    console.log(tempo[0].name);
    return tempo[0].name;
  }
};
