import React, { useState, useEffect } from "react";
import {
  InputGroup,
  Form,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  Col,
  Row,
} from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { useHistory } from "react-router";
import logo from "../../img/logoNoBackground.png";
import { connect } from "react-redux";

function HeaderContainer({ units }) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [unit, setUnit] = React.useState({ name: "login", id: null });
  const [loginDetails, setLoginDetails] = React.useState({
    user: "",
    password: "",
  });

  //const [password, setPassword] = useState(null);
  //const Login = (details) => {
  //  console.log(details);
  //};

  //const Logout = () => {
  //  console.log("logout");
  //};

  const checkLoginDetails = () => {
    if (loginDetails.user === loginDetails.password) {
      changeUser(loginDetails.user);
    } else console.log("wrong passowrd");
  };

  useEffect(() => {
    const userData = window.localStorage.getItem("UserID");
    const unitData = window.localStorage.getItem("UnitName");
    setUser(JSON.parse(userData));
    setUnit(JSON.parse(unitData));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("UserID", JSON.stringify(user));
    window.localStorage.setItem("UnitName", JSON.stringify(unit));
  });

  const logout = () => {
    setUnit({ name: "login", id: null });
    setUser(null);
    window.location.reload(false);
  };

  const changeUser = (id) => {
    setUser(id);
    if (units.filter((key) => key.id === id)[0]) {
      setUnit(units.filter((key) => key.id === id)[0]);
    } else {
      setUnit(null);
    }
    window.location.reload(false);
  };
  //console.log(units.filter((key) => key.id == user)[0].name);

  //setUnit(units.filter((key) => key.id === "84"));

  return (
    <Navbar id="header" expand="lg">
      <Navbar.Brand href="/" style={{ paddingRight: "3rem" }}>
        <img src={logo} alt="BasedOn" width="100" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">HOME</Nav.Link>
          <Nav.Link href="/trace">TRACE</Nav.Link>
          <NavDropdown title="YOUR LIBRARY" id="basic-nav-dropdown">
            <NavDropdown.Item href="/decisions">Decisions</NavDropdown.Item>
            <NavDropdown.Item href="/information">Information</NavDropdown.Item>
            <NavDropdown.Item href="/received">Received</NavDropdown.Item>
            <NavDropdown.Item href="/tasks">Tasks</NavDropdown.Item>
            <NavDropdown.Item href="/meetings">Meetings</NavDropdown.Item>
            <NavDropdown.Item href="/people">People</NavDropdown.Item>
            <NavDropdown.Item href="/units">Units</NavDropdown.Item>
          </NavDropdown>
          <div id="search">
            <InputGroup id="searchInputGroup">
              <Form.Control type="text" placeholder="Search" />
              <InputGroup.Append>
                <Button
                  className="rounded-1"
                  variant="outline-secondary"
                  type="submit"
                >
                  <GoSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </Nav>
        <div id="header-username">
          {!user ? (
            <div>
              <Form>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <InputGroup className="mb-2">
                      <Form.Control
                        size="sm"
                        as="select"
                        name="unit"
                        onChange={(e) =>
                          setLoginDetails({
                            ...loginDetails,
                            user: units.filter(
                              (key) => key.name === e.target.value
                            )[0].id,
                          })
                        }
                      >
                        <option>Choose unit</option>
                        {units.map((elem) => (
                          <option key={elem.id} data-key={elem.id}>
                            {elem.name}
                          </option>
                        ))}
                      </Form.Control>
                    </InputGroup>
                  </Col>
                  <Col xs="auto">
                    <InputGroup className="mb-2">
                      <Form.Control
                        id="inlineFormInputGroup"
                        placeholder="Password"
                        size="sm"
                        onChange={(e) =>
                          setLoginDetails({
                            ...loginDetails,
                            password: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col xs="auto">
                    <InputGroup className="mb-2">
                      <Button
                        type="submit"
                        className="mb-2"
                        variant="outline-secondary"
                        size="sm"
                        style={{ height: "2rem" }}
                        onClick={checkLoginDetails}
                      >
                        Login
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            <div>
              Unit: {unit.name}{" "}
              <Button
                type="button"
                variant="outline-secondary"
                size="sm"
                style={{ height: "2rem" }}
                onClick={() => logout()}
              >
                logout
              </Button>{" "}
            </div>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

//export default HeaderContainer;

export default connect(
  (state) => {
    return {
      decisions: state.data.decisions,
      information: state.data.information,
      loading: state.data.loading,
      units: state.data.units,
    };
  },
  (dispatch) => {
    return {};
  }
)(HeaderContainer);

/* 
<Form.Group as={Row}>
                                <Form.Label id="formLabel" column sm={2}>Unit</Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                        size="sm" 
                                        as="select" 
                                        value={unit.name} 
                                        onChange={e => {handleChange({name: e.target.value, id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key')}, setUnit)}}
                                    >
                                    <option>Choose one</option>
                                        {units.map(elem => 
                                            <option key={elem.id} data-key={elem.id} >{elem.name}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                            </Form.Group> */

/* 
<Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Unit:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="unit"
                    onChange={(e) =>
                      changeUser(
                        units.filter((key) => key.name === e.target.value)[0].id
                      )
                    }
                  >
                    <option>Choose one</option>
                    {units.map((elem) => (
                      <option key={elem.id} data-key={elem.id}>
                        {elem.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
*/

/*
<Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Unit:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="unit"
                    onChange={(e) =>
                      setLoginDetails({
                        ...loginDetails,
                        user: units.filter(
                          (key) => key.name === e.target.value
                        )[0].id,
                      })
                    }
                  >
                    <option>Choose one</option>
                    {units.map((elem) => (
                      <option key={elem.id} data-key={elem.id}>
                        {elem.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Password:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="password"
                    name="password"
                    onChange={(e) =>
                      setLoginDetails({
                        ...loginDetails,
                        password: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Button onClick={checkLoginDetails}></Button>
*/
