import React from 'react'
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import logo from '../../img/logoNoBackground.png'

const MatterModal = ({show, onHide, saveFunc, members, people, matters, editID }) => {

    const [matter, setMatter] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [responsible, setResponsible] = React.useState({name:'None', id:null})
    const [start, setStart] = React.useState('')
    const [end, setEnd] = React.useState('')
    const [progress, setProgress] = React.useState('')

    const save = () => {
        const data = {
            matter: matter,
            description: description,
            responsible: responsible.name === 'None' ? null : responsible.name,
            start: start,
            end: end,
            progress: progress
        }

        saveFunc(data)
        close()
    }

    const close = () => {
        // reset for next time
        setMatter('')
        setDescription('')
        setResponsible('None')
        setStart('')
        setEnd('')
        setProgress('')

        onHide()
    }

    const memOption = (m) => {
        const person = people.filter(p => p.id === m.personID)[0]
        return <option key={person.id} data-key={person.id} >{person.firstname + ' ' + person.lastname}</option>
    }

    return (
        <Modal
            show={show} 
            onHide={close}
            centered
            size="lg"
            >
            <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                <Modal.Title>
                    <Row>
                        <Col md={2}>
                            <img src={logo} alt="BasedOn" width="60" />
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Form.Group>
                        <Form.Label>Matter</Form.Label>
                        <Form.Control size="sm" type="text" value={matter} onChange={(e) => setMatter(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control size="sm" as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Responsible</Form.Label>
                        <Form.Control size="sm" as="select" value={responsible.name} onChange={(e) => setResponsible({name: e.target.value, id: e.target.options[e.target.options.selectedIndex].getAttribute('data-key')})} >
                            <option data-key={null} >None</option>
                            {members && members.length !== 0
                                ? members.map(m => memOption(m))
                                : <option disabled >This unit has no members</option>
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Start</Form.Label>
                        <Form.Control size="sm" type="date" value={start} onChange={(e) => setStart(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>End</Form.Label>
                        <Form.Control size="sm" type="date" value={end} onChange={(e) => setEnd(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Progress</Form.Label>
                        <Form.Control size="sm" as="select" value={progress} onChange={(e) => setProgress(e.target.value)} >
                            <option>Not started</option>
                            <option>In progress</option>
                            <option>Finished</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
            <Button 
                style={{fontSize:'15px', width:'100px'}} 
                variant="outline-info"
                onClick={save} 
            >
                Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MatterModal