import "../../styles/Trace.css";
import "../../styles/Decisions.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, Spinner } from "react-bootstrap";
import TraceTabsView from "../views/TraceTabsView";
import {
  getAllMeetings,
  getAllReccurences,
  setCurrentDecision,
} from "../../redux/actions/data";
import { getFilteredDecisions, getSubUnits } from "../../functions/filterData";

function TraceContainer({
  decisions,
  units,
  loading,
  meetings,
  matters,
  reccurences,
  categories,
  getAllMeetings,
  getAllReccurences,
  setCurrentDecision,
}) {
  useEffect(() => {
    getAllMeetings();
    getAllReccurences();
  }, []);

  const userID = JSON.parse(window.localStorage.getItem("UserID"));
  let subUnits = userID && units ? getSubUnits(userID, units) : null;
  const filteredDecisions = getFilteredDecisions(decisions, subUnits);
  //console.log(filteredDecisions);

  return (
    <Col id="mainviewcol">
      {loading || !decisions ? (
        <Col
          md={12}
          className="d-flex justify-content-center"
          style={{ paddingTop: "5%" }}
        >
          <Spinner animation="border" />
        </Col>
      ) : (
        <TraceTabsView
          decisions={filteredDecisions}
          units={units}
          meetings={meetings}
          matters={matters}
          reccurences={reccurences}
          categories={categories}
          setCurrentDecision={setCurrentDecision}
        />
      )}
    </Col>
  );
}

export default connect(
  (state) => {
    return {
      decisions: state.data.decisions,
      units: state.data.units,
      loading: state.data.loading,
      meetings: state.data.meetings,
      matters: state.data.matters,
      reccurences: state.data.reccurences,
      categories: state.data.categories,
    };
  },
  (dispatch) => {
    return {
      getAllMeetings: () => dispatch(getAllMeetings()),
      getAllReccurences: () => dispatch(getAllReccurences()),
      setCurrentDecision: (id) => dispatch(setCurrentDecision(id)),
    };
  }
)(TraceContainer);
