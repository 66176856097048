import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { IoMdOpen } from 'react-icons/io'
import { BsThreeDots } from 'react-icons/bs'

function DecisionsByCategory({decisions, categories, viewDecision }) {

    const [chosen, setChosen] = React.useState([])
    const [show, setShow] = React.useState(false)
    const [filteredDec, setFilteredDec] = React.useState([])
    const htmlRegex = /<[a-z/]*>/g

    const getFilterString = () => {
        if(chosen.length === 0 || chosen.length === categories.length) return <p id="categoryHeader" >All</p>
        else return chosen.map(c => c > -1 ? categories.filter(ca => ca.id === c)[0].category : 'No category').map(cf => <p key={cf} id="categoryHeader" >{cf}</p>)
    }

    const changeFilter = (id) => {
        const elem = document.getElementById('cf' + id)
        elem.classList.toggle('chosen')
        if(chosen.indexOf(id) === -1) {
            setChosen([...chosen, id])
            setFilteredDec(decisions.filter(d => (chosen.indexOf(d.category.id) !== -1 || d.category.id === id || (chosen.includes(-1) && !d.category.id))))
        }
        else {
            setChosen(chosen.filter(c => c !== id))
            setFilteredDec(decisions.filter(d => ((chosen.indexOf(d.category.id) !== -1 && d.category.id !== id) || (chosen.includes(-1) && !d.category.id))))
        }
    }

    const chooseAll = () => {
        const arrTmp = []
        categories.forEach(c => {
            document.getElementById('cf' + c.id).classList.add('chosen')
            arrTmp.push(c.id)
        })
        setChosen(arrTmp)
        setFilteredDec(decisions)
    }

    const chooseNone = () => {
        categories.forEach(c => {
            document.getElementById('cf' + c.id).classList.remove('chosen')
        })
        setChosen([])
        setFilteredDec(decisions)
    }

    const chooseNoFilter = () => {
        document.getElementById('cfNoCategory').classList.toggle('chosen')
        const add = (chosen.indexOf(-1) === -1)
        add ? setChosen([...chosen, -1]) : setChosen(chosen.filter(c => c !== -1))
        if( add ) setFilteredDec(decisions.filter(d => (chosen.indexOf(d.category.id) !== -1 || !d.category.id)))
        else setFilteredDec(decisions.filter(d => (chosen.indexOf(d.category.id) !== -1 && d.category.id)))
    }

    const createDecisionRow = (dec) => {
        return (
            <Row key={dec.id} id="tabContentTableRow" >
                <Col md={4}>
                    {(dec.title.length >= 100) ? dec.title.substring(0,100) + '...' : dec.title}
                </Col>
                <Col md={5}>
                    <div dangerouslySetInnerHTML={{__html:(dec.decisionText.length > 100 ? dec.decisionText.replaceAll(htmlRegex,'').substring(0,100)+'...' : dec.decisionText.replaceAll(htmlRegex,''))}}/>
                </Col>
                <Col md={2}>
                    {dec.category.name}
                </Col>
                <Col md={1}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'18px', height:'18px'}} onClick={() => viewDecision(dec.id)} />
                </Col>
            </Row>
        )
    }

    return (
        <>
        <Row>
            <Col md={12}>
                <p style={{fontSize:'1.3rem'}} >Choose categories <BsThreeDots id="addFilterBtn" onClick={() => setShow(true)}/> </p>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                {getFilterString()}
            </Col>
        </Row>
        <Row id="tabContentHeader" >
            <Col md={4}>
                ISSUE
            </Col>
            <Col md={5}>
                DECISION
            </Col>
            <Col md={2}>
                CATEGORY
            </Col>
            <Col md={1}>
                VIEW
            </Col>
        </Row>
        <div>
            {(chosen.length === 0 || chosen.length === categories.length) 
                ? decisions.map(cd => createDecisionRow(cd)) 
                : filteredDec.map(cd => createDecisionRow(cd))}
        </div>
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Body>
                Choose what categories to show
                <div  >
                    {categories && categories.map(c => 
                        <p key={c.id} 
                            id={"cf" + c.id} 
                            className={"categoryFilter" + ((chosen.indexOf(c.id) === -1 ) ? '' : ' chosen')} 
                            onClick={() => changeFilter(c.id)} >
                                {c.category} 
                        </p>
                    )}
                    <br/><br/>
                    <p id="cfAll" className='categoryFilter' onClick={() => chooseAll()} >All</p>
                    <p id="cfNone" className='categoryFilter' onClick={() => chooseNone()} >None</p>
                    <p id="cfNoCategory" className='categoryFilter' onClick={() => chooseNoFilter()} >No category</p>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default DecisionsByCategory