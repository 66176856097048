import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Row, Col, Form, Button } from "react-bootstrap";

function PeopleView({ person, update, unitMembers }) {
  // Todo: check if something changed and then if they change current or go to list warn that it will be lost.

  const [firstname, setFirstName] = React.useState(person.firstname);
  const [lastname, setLastName] = React.useState(person.lastname);
  const [status, setStatus] = React.useState(person.status);
  const [title, setTitle] = React.useState(person.title);
  const [gender, setGender] = React.useState(person.gender);
  const [education, setEducation] = React.useState(person.education);
  const [phone, setPhone] = React.useState(person.phone);
  const [email, setEmail] = React.useState(person.email);
  const [office, setOffice] = React.useState(person.office);
  const [website, setWebsite] = React.useState(person.website);

  const [saved, setSaved] = React.useState(false);

  useEffect(() => {
    if (person) {
      setFirstName(person.firstname);
      setLastName(person.lastname);
      setStatus(person.status);
      setTitle(person.title);
      setGender(person.gender);
      setEducation(person.education);
      setPhone(person.phone);
      setEmail(person.email);
      setOffice(person.office);
      setWebsite(person.website);
    }
  }, [person]);

  const updateFunc = (e) => {
    e.preventDefault();
    setStatus(getStatus());
    const data = {
      id: person.id,
      firstname: firstname,
      lastname: lastname,
      title: title,
      gender: gender,
      education: education,
      phone: phone === "" ? null : phone,
      email: email,
      office: office,
      website: website,
      status: status,
    };

    update(person.id, data);
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 2000);
  };
  console.log(person.firstname);
  const getStatus = () => {
    // status is set according to if the person is in a unit. This should possibly include more factors
    const member = unitMembers.filter((um) => um.personID === person.id);
    if (member.length === 0) return "Inactive";
    else return "Active";
  };

  return (
    <Row>
      <Form
        style={{
          width: "100%",
          padding: "2rem",
          borderBottom: "1px solid var(--sidebar-gray)",
          marginBottom: "2rem",
        }}
      >
        <Row>
          <Col md={6}>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                First name
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Last name
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm="2">
                Status
              </Form.Label>
              <Col md={10}>
                <Form.Control size="sm" readOnly value={status} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Title
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Gender
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  as="select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option>Female</option>
                  <option>Male</option>
                  <option>Non-binary</option>
                  <option>Other</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Education
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Phone
              </Form.Label>
              <Col md={10}>
                <PhoneInput
                  country={"se"}
                  value={phone}
                  onChange={(p) => setPhone(p)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Email
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Office
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={office}
                  onChange={(e) => setOffice(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Website
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                style={{ marginTop: "35px" }}
                id="saveBtn"
                variant="secondary"
                type="submit"
                onClick={(e) => updateFunc(e)}
              >
                {saved ? "Successfully saved" : "Save"}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default PeopleView;
