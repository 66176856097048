import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'

function AddressSubunit({current, save}) {

    const [mainStreet, setMainStreet] = React.useState('')
    const [mainCity, setMainCity] = React.useState('')
    const [mainPostal, setMainPostal] = React.useState('')
    const [secondStreet, setSecondStreet] = React.useState('')
    const [secondCity, setSecondCity] = React.useState('')
    const [secondPostal, setSecondPostal] = React.useState('')

    const [saved, setSaved] = React.useState(false)

    const saveFunc = () => {
        const data = {
            main: mainStreet ? {
                    street: mainStreet,
                    city: mainCity,
                    postalCode: mainPostal
                } : null,
            second: secondStreet ?{
                    street: secondStreet,
                    city: secondCity,
                    postalCode: secondPostal
                } : null
        }

        save(current.id, data)
        setSaved(true)
        setTimeout(() => {
            setSaved(false)
        }, 2000)
    }

    const checkAddress = () => {
        if((mainStreet && mainCity && mainPostal) || (secondStreet && secondCity && secondPostal)) return true
        if(!mainStreet && !mainCity && !mainPostal && !secondStreet && !secondCity & !secondPostal) return true
        else return false
    }

    return (
        <Row>
        <Form style={{width:'100%', padding:'2rem', borderBottom:'1px solid var(--sidebar-gray)', marginBottom:'2rem'}} >
            <Row>
                
                <Col md={6}>
                    <p>Main address</p>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>Street</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={mainStreet} onChange={(e) => setMainStreet(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>City</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={mainCity} onChange={(e) => setMainCity(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>Postal code</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={mainPostal} onChange={(e) => setMainPostal(e.target.value)} />
                        </Col>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <p>Second address</p>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>Street</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={secondStreet} onChange={(e) => setSecondStreet(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>City</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={secondCity} onChange={(e) => setSecondCity(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label id="formLabel" column sm={2}>Postal code</Form.Label>
                        <Col sm={10} >
                            <Form.Control size="sm" type="text" value={secondPostal} onChange={(e) => setSecondPostal(e.target.value)} />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={{span:4, offset:4}}>
                    <Form.Group as={Row}>
                        <Button 
                            id="saveBtn" 
                            variant="secondary" 
                            type="submit" 
                            disabled={!checkAddress()}
                            onClick={(e) => {
                                e.preventDefault()
                                if(checkAddress()) saveFunc()
                                else console.log('faulty address')
                            }} >
                                {saved ? 'Saved successfully' : (checkAddress()) ? 'Save' : 'Not valid address'}
                        </Button>
                    </Form.Group>
                    
                </Col>
            </Row>

        </Form>
    </Row>
    )
}

export default AddressSubunit