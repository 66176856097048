import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { getActionTitle } from "../../functions/directionActionTitle";

function DecisionView({ editBtn, decision, createUpperDecString }) {
  return (
    <React.Fragment>
      <div
        style={{
          borderBottom: "1px solid var(--sidebar-gray)",
          paddingBottom: "1rem",
          marginBottom: "2.5rem",
        }}
      >
        <Row style={{ fontSize: "2rem", paddingTop: "1%" }}>
          <Col md={10}>{decision.title}</Col>
          <Col md={2}>
            <Button id="editbtn" variant="outline-secondary" onClick={editBtn}>
              Edit
            </Button>
          </Col>
        </Row>
      </div>
      <Row style={{ paddingBottom: "70px" }}>
        <Col id="maininfo" md={9}>
          <Row>
            <Col md={1} style={{ color: "gray" }}>
              Decision
            </Col>
            <Col md={11} id="decisionText">
              <div
                dangerouslySetInnerHTML={{ __html: decision.decisionText }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={1} style={{ color: "gray" }}>
              Basis
            </Col>
            <Col md={11}>
              <div dangerouslySetInnerHTML={{ __html: decision.basis }} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={1} style={{ color: "gray" }}>
              Direction
            </Col>
            <Col md={11}>
              <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                  <b>T I T L E</b>
                </Col>
                <Col me={10}>
                  {decision.direction
                    ? decision.direction.name
                    : "No direction"}
                </Col>
              </Row>
              {decision.direction && (
                <>
                  <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                    <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                      <b>D A T E</b>
                    </Col>
                    <Col me={10}>{decision.direction.date}</Col>
                  </Row>
                  <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                    <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                      <b>S T A G E S</b>
                    </Col>
                    <Col me={10}>
                      <Row
                        style={{
                          width: "100%",
                          borderBottom: "2px solid var(--sidebar-gray)",
                        }}
                      >
                        <Col md={4}>Name</Col>
                        <Col md={4}>Description</Col>
                        <Col md={4}>Action</Col>
                      </Row>
                      {decision.direction.stages.map((s, i) => (
                        <Row
                          style={{
                            width: "100%",
                            borderBottom: "1px dotted var(--sidebar-gray)",
                          }}
                        >
                          <Col md={4}>{s.name}</Col>
                          <Col md={4}>{s.description}</Col>
                          <Col md={4}>
                            {getActionTitle(decision.direction.stages, i)}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col id="sideinfo" md={3} style={{ fontSize: "0.9rem", color: "gray" }}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>Category:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.category === undefined
                      ? "No category"
                      : decision.category.name}
                  </b>{" "}
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Unit:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.ownerUnit === undefined
                      ? "No owner unit"
                      : decision.ownerUnit.name}{" "}
                  </b>{" "}
                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Keywords:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.keywords.map((k) => (
                      <b
                        key={k.id}
                        style={{
                          cursor: "default",
                          color: "white",
                          backgroundColor: "var(--info)",
                          borderRadius: "15px",
                          margin: "5px",
                          padding: "2px 5px",
                        }}
                      >
                        {k.text + " "}
                      </b>
                    ))}{" "}
                  </b>{" "}
                  <br />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Matter:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>{decision.matter.name}</b>{" "}
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Upper Decision:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {createUpperDecString(decision.upperDecisionID)}{" "}
                  </b>
                  <br />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={4}>Reference Nr:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>{decision.referenceNr} </b>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Decision Date:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.decisionDate === null
                      ? "Not set"
                      : decision.decisionDate}{" "}
                  </b>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Valid From:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.validFrom === null
                      ? "Not set"
                      : decision.validFrom}{" "}
                  </b>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={4}>Valid To:</Col>
                <Col md={8}>
                  <b style={{ float: "right" }}>
                    {decision.validTo === null ? "Not set" : decision.validTo}{" "}
                  </b>
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              style={{
                borderTop: "1px solid var(--sidebar-gray)",
                marginTop: "4vh",
                paddingTop: "3vh",
              }}
            >
              CREATED BY{" "}
              <b style={{ float: "right" }}>
                {/*TODO get username*/ decision.createdBy}
              </b>{" "}
              <br />
              DATE <b style={{ float: "right" }}>
                {decision.creationDate}
              </b>{" "}
              <br />
              LAST MODIFIED BY{" "}
              <b style={{ float: "right" }}>
                {/*TODO get username*/ decision.lastModifier}
              </b>{" "}
              <br />
              DATE{" "}
              <b style={{ float: "right" }}>{decision.lastModifiedDate} </b>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default DecisionView;
