import React from 'react'
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import logo from '../../img/logoNoBackground.png'

const DecisionAddRecurrencesModal = ({show, onHide, saveFunc }) => {

    const [title, setTitle] = React.useState('')
    const [date, setDate] = React.useState('')
    const [hasEndDate, setHasEndDate] = React.useState(false)
    const [endDate, setEndDate] = React.useState('')
    const [status, setStatus] = React.useState('')
    const [note, setNote] = React.useState('')

    const updateStatus = (dates) => {
        const start = new Date(dates.start)
        const end = dates.end !== '' ? new Date(dates.end) : null
        const today = new Date()
        if(dates.start === '') setStatus('No date')
        else if(end && end < start ) setStatus('End before start')
        else if(start > today) setStatus('Coming up')
        else if(end && end < today) setStatus('Finished')
        else setStatus('Active')
    }

    const save = () => {
        const data = {
            title: title === '' ? null : title,
            date: date === '' ? null : date,
            endDate: endDate === '' ? null : endDate,
            status: status === '' ? null : status,
            note: note === '' ? null : note
        }
        saveFunc(data)
        onHide()
    }

    // const close = () => {
    //     // reset for next time
    //     setTitle('')
    //     setLocation('')
    //     setDateTime('')
    //     setDurationHours(0)
    //     setDurationMins(0)

    //     onHide()
    // }

    return (
        <Modal
            show={show} 
            onHide={onHide}
            centered
            size="lg"
            >
            <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                <Modal.Title>
                    <Row>
                        <Col md={2}>
                            <img src={logo} alt="BasedOn" width="60" />
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Form.Group as={Row}>
                        <Col md={3}>
                            <Form.Label>Date</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control size="sm" type="date" required value={date} onChange={(e) => {updateStatus({start: e.target.value, end: endDate}); setDate(e.target.value)}} />
                        </Col>
                        
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Col md={3}>
                            <Form.Label>Add end date</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Check type="checkbox" value={hasEndDate} onChange={() => setHasEndDate(!hasEndDate)} />
                        </Col>
                    </Form.Group>
                    {hasEndDate ? (
                        <Form.Group as={Row}>
                            <Col md={3}>
                                <Form.Label>End date</Form.Label>
                            </Col>
                            <Col md={9}>
                                <Form.Control size="sm" type="date" required value={endDate} onChange={(e) => {updateStatus({start: date, end: e.target.value}); setEndDate(e.target.value)}} />
                            </Col>
                        
                        </Form.Group>
                    ) : ('')}
                    <Form.Group as={Row}>
                        <Col md={3} >
                            <Form.Label>Title (optional)</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control size="sm" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col md={3}>
                            <Form.Label>Note (optional)</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control size="sm" as="textarea" value={note} onChange={(e) => setNote(e.target.value)} />
                        </Col>
                        
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
            <Button 
                style={{fontSize:'15px', width:'100px'}} 
                variant="outline-info"
                onClick={save} 
            >
                Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DecisionAddRecurrencesModal