export const getActionTitle = (stages, index) => {
  var st = "";
  console.log(stages[index].action);
  if (stages[index].action != null) {
    if (stages[index].action.emailTo) st += "Email ";
    if (stages[index].action.document) st += "Document ";
    if (stages[index].action.approval) st += "Approval ";
    if (stages[index].action.condition) st += "Condition ";
  }
  console.log(st);
  return st;
};
