import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import guLogo from "../../img/guLogo.png";

function UnitView({
  units,
  currentUnit,
  deleteUnit,
  update,
  unitString,
  backBtn,
}) {
  const [name, setName] = React.useState(
    currentUnit.name ? currentUnit.name : ""
  );
  const [upperUnitID, setUpperUnitID] = React.useState(
    currentUnit.superUnitID ? currentUnit.superUnitID : ""
  );
  const [shortName, setShortName] = React.useState(
    currentUnit.shortName ? currentUnit.shortName : ""
  );
  const [type, setType] = React.useState(
    currentUnit.type ? currentUnit.type : ""
  );
  const [startDate, setStartDate] = React.useState(
    currentUnit.startDate ? currentUnit.startDate : ""
  );
  const [endDate, setEndDate] = React.useState(
    currentUnit.endDate ? currentUnit.endDate : ""
  );
  const [status, setStatus] = React.useState(
    currentUnit.status ? currentUnit.status : ""
  );
  const [description, setDescription] = React.useState(
    currentUnit.description ? currentUnit.description : ""
  );

  useEffect(() => {
    if (units && currentUnit) {
      setName(currentUnit.name);
      setUpperUnitID(currentUnit.superUnitID);
      setShortName(currentUnit.shortName);
      setType(currentUnit.type);
      setStartDate(currentUnit.startDate);
      setEndDate(currentUnit.endDate);
      setStatus(currentUnit.status);
      setDescription(currentUnit.description);
    }
  }, [currentUnit]);

  // Disclaimer: This is hard coded for testing purposes, the logos should later be saved in database and this removed
  const [logo, setLogo] = React.useState(currentUnit.id === "84" ? guLogo : "");
  const [logoName, setLogoName] = React.useState(
    currentUnit.id === "84" ? "guLogo.png" : ""
  );

  const [saved, setSaved] = React.useState(false);

  const updateStatus = (dates) => {
    const start = new Date(dates.start);
    const end = new Date(dates.end);
    const today = new Date();
    if (!dates.start) setStatus("No start date");
    else if (end < start) setStatus("End before start");
    else if (start > today) setStatus("Not started");
    else if (endDate && end < today) setStatus("Finished");
    else setStatus("Active");
  };

  const updateFunc = (e) => {
    e.preventDefault();
    console.log(upperUnitID);
    const data = {
      id: currentUnit.id,
      name: name,
      shortName: shortName,
      description: description,
      upperUnitID: upperUnitID,
      type: type,
      status: status,
      startDate: startDate,
      endDate: endDate,
    };
    console.log(description);

    update(data);
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 2000);
  };

  return (
    <Row>
      <Form
        style={{
          width: "100%",
          padding: "2rem",
          borderBottom: "1px solid var(--sidebar-gray)",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid var(--sidebar-gray)",
            paddingBottom: "1rem",
            paddingTop: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Row style={{ fontSize: "2rem", paddingTop: "1%" }}>
            <Col md={10}> View: {name}</Col>
            <Col md={2}>
              <Button
                id="backbtn"
                variant="outline-secondary"
                size="sm"
                onClick={backBtn}
              >
                Back
              </Button>

              <Button id="resetbtn" size="sm" onClick={deleteUnit}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Unit name
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Upper unit
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  as="select"
                  value={unitString(upperUnitID)}
                  onChange={(e) => setUpperUnitID(e.target.value.split("-")[0])}
                >
                  <option>No upper unit</option>
                  {units.map((u) => {
                    return <option key={u.id}>{unitString(u.id)}</option>;
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Short name
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                />
              </Col>
            </Form.Group>
            {/** TODO Fråga Faramarz hur det i databasen ska tydas */}
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Type
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  as="select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>Decisional unit</option>
                  <option>Business area</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Start date
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    updateStatus({ start: e.target.value, end: endDate });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                End date
              </Form.Label>
              <Col md={10}>
                <Form.Control
                  size="sm"
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    updateStatus({ start: startDate, end: e.target.value });
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm="2">
                Status
              </Form.Label>
              <Col md={10}>
                <Form.Control size="sm" readOnly value={status} />
              </Col>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as={Row}>
              <Form.Label id="formLabel" column sm={2}>
                Description
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  style={{ maxHeight: "207px" }}
                  size="sm"
                  as="textarea"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{ minHeight: "110px" }}>
              <Form.Label id="formLabel" column sm={2}>
                Logo
              </Form.Label>
              <Col sm={6}>
                <Form.File
                  size="sm"
                  label={logoName ? logoName : "Choose a file"}
                  custom
                  accept=".tif, .tiff,.jpg, .jpeg, .gif, .png, .eps, .raw"
                  onChange={(e) => {
                    setLogoName(e.target.files[0].name);
                    setLogo(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </Col>
              <Col md={4}>{logo && <img src={logo} height="100px" />}</Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                style={{ marginTop: "35px" }}
                id="saveBtn"
                variant="secondary"
                type="submit"
                onClick={(e) => updateFunc(e)}
              >
                {saved ? "Successfully saved" : "Save"}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default UnitView;
