import React, { useEffect } from "react";
import "../../styles/Unit.css";
import {
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setCurrentUnit,
  updateUnit,
  updateMemberHours,
  setCurrentDecision,
  createMeeting,
  getMeetings,
  removeMeeting,
  createMatter,
  removeMatter,
  updateMatter,
  addMember,
  deleteUnit,
  deleteMember,
} from "../../redux/actions/data";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaFileDownload, FaRegListAlt, FaPlusSquare } from "react-icons/fa";
import { GiFamilyTree } from "react-icons/gi";
import UnitView from "../views/UnitView";
import UnitTabsView from "../views/UnitTabsView";
import UnitExtractModal from "../modals/UnitExtractModal";
import UnitAddNewView from "../views/UnitAddNewView";
import {
  getFilteredDecisions,
  getSubUnits,
  getFilteredSubUnits,
  getSuperUnitID,
} from "../../functions/filterData";

function UnitContainer({
  units,
  loading,
  currentUnit,
  unitMembers,
  people,
  decisions,
  meetings,
  matters,
  setCurrentUnit,
  updateUnit,
  updateMemberHours,
  setCurrentDecision,
  createMeeting,
  getMeetings,
  removeMeeting,
  createMatter,
  removeMatter,
  updateMatter,
  addMember,
  addNewUnit,
  deleteUnit,
}) {
  const history = useHistory();
  const [showExtract, setShowExtract] = React.useState(false);
  const [addNew, setAddNew] = React.useState(false);
  const [nextId, setNextId] = React.useState(0);
  const [curIndex, setCurIndex] = React.useState(0);

  let userID = JSON.parse(window.localStorage.getItem("UserID"));

  useEffect(() => {
    if (currentUnit && currentUnit.id) {
      getMeetings(currentUnit.id);
      console.log(currentUnit.id);
    }
    if (units && currentUnit) {
      setCurIndex(units.indexOf(currentUnit));
    }
  }, [currentUnit]);

  const addNewBtnFunc = () => {
    const ids = units.map((user) => user.id);
    const sorted = ids.sort((a, b) => a - b);
    const maxId = parseInt(sorted[sorted.length - 1]);
    setNextId(String(maxId + 1));
    console.log(nextId);
    setAddNew(true);
    setCurrentUnit(null);
  };

  const addNewBackBtnFunc = () => {
    setAddNew(false);
    setCurrentUnit(null);
  };

  const unitString = (id) => {
    let te = units.filter((key) => key.id === id);
    let namn = te.map((ke) => ke.name);
    if (id) return id + "-" + namn;
    else return "No upper unit";
  };

  const viewDecision = (id) => {
    setCurrentDecision(id);
    history.push("/decisions");
  };

  const handleDeleteUnit = (id) => {
    deleteUnit(currentUnit.id);
    setCurrentUnit(null);
  };

  const createMeetingFunc = (data) => {
    createMeeting(currentUnit.id, data);
  };

  const handleSwitch = (index) => {
    setCurIndex(index);
    setCurrentUnit("" + units[index].id);
  };

  const createMatterFunc = (data) => {
    createMatter(currentUnit.id, data);
  };

  const updateMatterFunc = (data) => {
    updateMatter(currentUnit.id, data);
  };

  const handleAddNewUnit = (data) => {
    addNewUnit(data);
    setAddNew(false);
  };

  let subUnits = userID && units ? getSubUnits(userID, units) : null;

  const filteredSubUnits = getFilteredSubUnits(units, subUnits);

  return (
    <Col id="mainviewcol">
      <Row style={{ height: "3rem", backgroundColor: "lightgrey" }}>
        <Col md={2} style={{ fontSize: "1.7rem", paddingTop: "0.3rem" }}>
          UNITS
        </Col>
        {!addNew && !currentUnit ? (
          <Col
            md={{ span: 3, offset: 7 }}
            style={{ paddingLeft: "60px", textAlign: "center" }}
          >
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip style={{ fontSize: "12px" }}>Add new</Tooltip>}
            >
              <FaPlusSquare id="decisionIcons" onClick={addNewBtnFunc} />
            </OverlayTrigger>
          </Col>
        ) : (
          <></>
        )}
        {currentUnit ? (
          <>
            <Col md={{ span: 2, offset: 5 }} id="decArrows">
              <BsArrowLeft
                id="arrowBtn"
                onClick={() => {
                  handleSwitch(
                    curIndex - 1 < 0 ? units.length - 1 : curIndex - 1
                  );
                }}
              />
              <Badge
                pill
                variant="info"
                style={{
                  fontSize: "1rem",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                {curIndex + 1} / {units.length}
              </Badge>
              <BsArrowRight
                id="arrowBtn"
                onClick={() => {
                  handleSwitch((curIndex + 1) % units.length);
                }}
              />
            </Col>
            <Col
              md={{ span: 3, offset: 0 }}
              style={{ paddingLeft: "60px", textAlign: "center" }}
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>Add new</Tooltip>
                }
              >
                <FaPlusSquare id="decisionIcons" onClick={addNewBtnFunc} />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>View all</Tooltip>
                }
              >
                <FaRegListAlt
                  id="decisionIcons"
                  onClick={() => setCurrentUnit(null)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>Extract</Tooltip>
                }
              >
                <FaFileDownload
                  id="decisionIcons"
                  onClick={() => setShowExtract(true)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>View tree</Tooltip>
                }
              >
                <GiFamilyTree
                  style={{ transform: "rotate(180deg" }}
                  id="decisionIcons"
                  onClick={() =>
                    history.push({ pathname: "/tree", state: { type: "unit" } })
                  }
                />
              </OverlayTrigger>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
      {loading || units.length === 0 ? (
        <Col
          md={12}
          className="d-flex justify-content-center"
          style={{ paddingTop: "5%" }}
        >
          <Spinner animation="border" />
        </Col>
      ) : currentUnit ? (
        <>
          <UnitView
            units={units}
            deleteUnit={handleDeleteUnit}
            currentUnit={currentUnit}
            update={updateUnit}
            unitString={unitString}
            backBtn={addNewBackBtnFunc}
          />
          <UnitTabsView
            current={currentUnit}
            deleteMember={deleteMember}
            members={unitMembers}
            people={people}
            updateMemberHours={updateMemberHours}
            decisions={decisions}
            viewDecision={viewDecision}
            createMeeting={createMeetingFunc}
            meetings={meetings}
            removeMeeting={removeMeeting}
            createMatter={createMatterFunc}
            matters={matters}
            removeMatter={removeMatter}
            updateMatter={updateMatterFunc}
            addMember={addMember}
          />
          <Row
            style={{
              fontSize: "0.9rem",
              color: "gray",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Col md={2}>
              {/** TODO username */}
              CREATED BY{" "}
              <b style={{ float: "right" }}>{currentUnit.createdBy}</b>
            </Col>
            <Col md={2}>
              DATE <b style={{ float: "right" }}>{currentUnit.creationDate}</b>
            </Col>
            <Col md={{ span: 2, offset: 4 }}>
              {/** TODO username */}
              LAST MODIFIED BY{" "}
              <b style={{ float: "right" }}>{currentUnit.lastModifier}</b>
            </Col>
            <Col md={2}>
              DATE{" "}
              <b style={{ float: "right" }}>{currentUnit.lastModifiedDate}</b>
            </Col>
          </Row>
          <UnitExtractModal
            show={showExtract}
            onHide={() => setShowExtract(false)}
            current={currentUnit}
            members={unitMembers}
            people={people}
            unitString={unitString}
          />
        </>
      ) : !addNew ? (
        <Col md={12} style={{ marginTop: "20px" }}>
          {filteredSubUnits.map((u) => (
            <Row>
              <div
                id="decListItems"
                className="w-100 pl-3 pr-3 pt-2 pb-0"
                key={u.id}
                onClick={() => setCurrentUnit(u.id)}
              >
                <Row>
                  <Col>
                    <h2 style={{ fontSize: 22 }}>{u.name}</h2>
                    <p style={{ fontSize: 18 }}>{u.description}</p>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <p
                      className="mt-1 mb-2"
                      style={{ textAlign: "end", fontSize: 16 }}
                    >
                      Upper unit: {getSuperUnitID(units, u.superUnitID)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Row>
          ))}
        </Col>
      ) : (
        <UnitAddNewView
          units={units}
          unitString={unitString}
          update={updateUnit}
          currentUnit={nextId}
          backBtn={addNewBackBtnFunc}
        />
      )}
    </Col>
  );
}

export default connect(
  (state) => {
    return {
      units: state.data.units,
      loading: state.data.loading,
      currentUnit: state.data.currentUnit,
      unitMembers: state.data.unitMembers,
      people: state.data.people,
      decisions: state.data.decisions,
      meetings: state.data.meetings,
      matters: state.data.matters,
    };
  },
  (dispatch) => {
    return {
      setCurrentUnit: (id) => dispatch(setCurrentUnit(id)),
      updateUnit: (data) => dispatch(updateUnit(data)),
      deleteUnit: (id) => dispatch(deleteUnit(id)),
      updateMemberHours: (member, hours) =>
        dispatch(updateMemberHours(member, hours)),
      setCurrentDecision: (id) => dispatch(setCurrentDecision(id)),
      createMeeting: (unitID, data) => dispatch(createMeeting(unitID, data)),
      getMeetings: (unitID) => dispatch(getMeetings(unitID)),
      removeMeeting: (unitID, meetingID) =>
        dispatch(removeMeeting(unitID, meetingID)),
      createMatter: (unitID, data) => dispatch(createMatter(unitID, data)),
      removeMatter: (unitID, matterID) =>
        dispatch(removeMatter(unitID, matterID)),
      updateMatter: (unitID, data) => dispatch(updateMatter(unitID, data)),
      addMember: (data) => dispatch(addMember(data)),
    };
  }
)(UnitContainer);
