import React from 'react'
import { Col, Row } from 'react-bootstrap'

function PeopleUnitsSubunit({current, units, unitMembers}) {

    const createUnitRow = (um) => {
        const unit = units.filter(u => u.id === um.unitID)[0]
        return (
            <Row id="tabContentTableRow" key={um.id} >
                <Col md={4}>
                    {unit.name}
                </Col>
                <Col md={2}>
                    {um.startDate && um.startDate.split(' ')[0]}
                </Col>
                <Col md={2}>
                    {um.endDate && um.endDate.split(' ')[0]}
                </Col>
                <Col md={1}>
                    {um.status}
                </Col>
                <Col md={{span: 2, offset:1}}>
                    {um.assignmentDate && um.assignmentDate.split(' ')[0]}
                </Col>
            </Row>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={4}>
                        NAME
                    </Col>
                    <Col md={2}>
                        START DATE
                    </Col>
                    <Col md={2}>
                        END DATE
                    </Col>
                    <Col md={1}>
                        STATUS
                    </Col>
                    <Col md={{span: 2, offset:1}}>
                        ASSIGNMENT DATE
                    </Col>
                </Row>
                <div id="tabContentTable">
                    {unitMembers.filter(um => um.personID === current.id).map(fum => createUnitRow(fum))}
                </div>
            </Col>
        </Row>
    )
}

export default PeopleUnitsSubunit