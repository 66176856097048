import React from 'react'
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import logo from '../../img/logoNoBackground.png'

const UnitMeetingModal = ({show, onHide, saveFunc }) => {

    const [title, setTitle] = React.useState('')
    const [location, setLocation] = React.useState('')
    const [dateTime, setDateTime] = React.useState('')
    const [durationHours, setDurationHours] = React.useState(0)
    const [durationMins, setDurationMins] = React.useState(0)

    const save = () => {
        const dateAndTime = dateTime.split('T')
        console.log(durationMins, typeof durationMins)
        const hours = durationHours === 0 ? '' : durationHours + 'h '
        const mins = durationMins === 0 ? '' : durationMins + 'min'
        const data = {
            title: title,
            location: location,
            dateTime: dateAndTime[0] + ' ' + dateAndTime[1],
            duration: hours + mins
        }
        saveFunc(data)
        close()
    }

    const close = () => {
        // reset for next time
        setTitle('')
        setLocation('')
        setDateTime('')
        setDurationHours(0)
        setDurationMins(0)

        onHide()
    }

    return (
        <Modal
            show={show} 
            onHide={close}
            centered
            size="lg"
            >
            <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                <Modal.Title>
                    <Row>
                        <Col md={2}>
                            <img src={logo} alt="BasedOn" width="60" />
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form>
                    <Form.Group as={Row}>
                        <Col md={2} >
                            <Form.Label>Meeting title</Form.Label>
                        </Col>
                        <Col md={10}>
                            <Form.Control size="sm" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col md={2} >
                            <Form.Label>Location</Form.Label>
                        </Col>
                        <Col md={10}>
                            <Form.Control size="sm" type="text" required value={location} onChange={(e) => setLocation(e.target.value)} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col md={2}>
                            <Form.Label>Date and time</Form.Label>
                        </Col>
                        <Col md={10}>
                            <Form.Control size="sm" type="datetime-local" required value={dateTime} onChange={(e) => setDateTime(e.target.value)} />
                        </Col>
                        
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Col md={2} >
                            <Form.Label>Duration</Form.Label>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label style={{fontSize:'0.8rem'}} >Hours</Form.Label>
                                <Form.Control size="sm" as="select" value={durationHours} onChange={(e) => setDurationHours(parseInt(e.target.value))} >
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => {
                                        return <option key={i} >{i}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label style={{fontSize:'0.8rem'}}>Minutes</Form.Label>
                                <Form.Control size="sm" as="select" value={durationMins} onChange={(e) => setDurationMins(parseInt(e.target.value))} >
                                    {[0, 15, 30, 45].map(i => {
                                        return <option key={i} >{i}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
            <Button 
                style={{fontSize:'15px', width:'100px'}} 
                variant="outline-info"
                onClick={save} 
            >
                Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UnitMeetingModal