import React from 'react'
import { Row, Col, Modal, Button } from "react-bootstrap";
import logo from '../../img/logoNoBackground.png'

const UnitExtractModal = ({show, onHide, current, members, people, unitString }) => {

    const validity = current ? (
        (current.startDate === null && current.endDate === null) 
            ? 'No specific period'
            : (current.startDate === null)
                ? '- ' + current.endDate
                : (!current.endDate) 
                    ? current.startDate + ' -'
                    : current.startDate + ' - ' + current.endDate
    ) : ''
    
    const createMemberView = (mem) => {
        const person = people.filter(p => p.id === mem.personID)[0]
        return (
            person ? (
                <p key={mem.id} >{person.firstname + ' ' + person.lastname}<b>{person.title ? ' - ' + person.title : ''}</b><br/></p>
            ) : ( <> </>)
            
        )
    }

    // Only returns Modal once first decision is clicked and current exists
    return (
        current ? (
        <Modal
            show={show} 
            onHide={() => onHide()}
            size="lg"
            >
            <div id="printSection">
                <Modal.Header >
                    <Modal.Title style={{width:'100%'}} >
                        <Row>
                            <Col md={2}>
                                <img src={logo} alt="BasedOn" width="60" />
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row style={{paddingBottom:'5px', paddingTop:'5px'}} >
                        <Col md={3} style={{color:'gray', fontSize:'12px'}}><b>N A M E</b></Col>
                        <Col md={9}>{current.name}</Col>
                    </Row>
                    {current.shortName !== '' ? (
                        <Row style={{paddingBottom:'5px', paddingTop:'5px'}} >
                        <Col md={3} style={{color:'gray', fontSize:'12px'}}><b>S H O R T</b></Col>
                        <Col md={9} style={{fontSize:'13px'}} >{current.name}</Col>
                    </Row>
                    ) : ''}
                    <div style={{borderTop: '1px solid var(--sidebar-gray)', margin:'10px 0px'}} >
                        {current.superUnitID ? (
                            <Row style={{paddingBottom:'5px', paddingTop:'5px'}} >
                                <Col md={3} style={{color:'gray', fontSize:'12px'}} ><b>U P P E R</b></Col>
                                <Col md={9} style={{fontSize:'13px'}}>
                                    {unitString(current.superUnitID)}
                                </Col>
                            </Row>
                        ) : ''}
                        <Row style={{paddingBottom:'5px', paddingTop:'5px'}}>
                            <Col md={3} style={{color:'gray', fontSize:'12px'}}><b>D E S C R I P T I O N</b></Col>
                            <Col md={9} style={{fontSize:'13px'}}>
                                {current.description}
                            </Col> 
                        </Row>
                    </div>
                    <div style={{borderTop: '1px solid var(--sidebar-gray)'}} >
                        <Row style={{paddingBottom:'5px', paddingTop:'5px'}} >
                            <Col md={3} style={{color:'gray', fontSize:'12px'}} ><b>M E M B E R S</b></Col>
                            <Col md={9} style={{fontSize:'12px'}} >
                                {members.filter(m => m.unitID === current.id).map(fm => createMemberView(fm))}
                            </Col>
                        </Row>
                    </div>
                    <div style={{borderTop: '1px solid var(--sidebar-gray)'}} >
                        <Row style={{paddingBottom:'5px', paddingTop:'5px'}} >
                            <Col md={3} style={{color:'gray', fontSize:'12px'}} ><b>V A L I D</b></Col>
                            <Col md={9} style={{fontSize:'12px'}} >
                                {validity}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
            <Modal.Footer >
                <Button id="btn" variant="outline-secondary" onClick={() => onHide()}>
                    Close
                </Button>
                <Button 
                    id="btn" 
                    variant="outline-secondary" 
                    onClick={() => {
                        window.print()
                    }}>
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
        ) : (
            <></>
        )
    )
}

export default UnitExtractModal