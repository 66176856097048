import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import logo from "../../img/logoNoBackground.png";

const AddMembersModal = ({
  show,
  onHide,
  title,
  saveFunc,
  current,
  people,
  members,
  closeWin,
}) => {
  const createPersonRow = (p) => {
    var isMember = false;
    members.forEach((m) => {
      if (m.unitID === current.id && m.personID === p.id) {
        isMember = true;
      }
    });
    return isMember ? null : (
      <Row id="membersTableRow" key={p.id}>
        <Col md={2}>
          <input
            type="submit"
            value="Add"
            onClick={(e) => {
              handleSave({
                id: getNextId(),
                personID: p.id,
                status: "Assigned",
                unitID: current.id,
                workingHours: "0",
                start: 0,
              });
            }}
          />
        </Col>
        <Col md={3}>{p.firstname}</Col>
        <Col md={3}>{p.lastname}</Col>
        <Col md={4}>{p.title}</Col>
      </Row>
    );
  };

  const getNextId = () => {
    const ids = members.map((user) => user.id);
    const sorted = ids.sort((a, b) => a - b);
    const maxId = parseInt(sorted[sorted.length - 1]);
    return maxId + 1;
  };
  console.log(getNextId());
  const handleSave = (data) => {
    saveFunc(data);
  };
  const addNew = people.filter((p) => createPersonRow(p));

  /* const [data, setData] = React.useState({
    id: "",
    tlc: "",
  });
  console.log(data); */
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header
        closeButton
        style={{ paddingTop: "7px", paddingBottom: "2px" }}
      >
        <Modal.Title>
          <Row>
            <Col md={2}>
              <img src={logo} alt="BasedOn" width="60" />
            </Col>
            <Col md={10}>Add {title}</Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="memberModBody">
        <Row style={{ margin: 0, backgroundColor: "var(--sidebar-gray)" }}>
          <Col md={2}>
            <b>Add</b>
          </Col>
          <Col md={3}>
            <b>First name</b>
          </Col>
          <Col md={3}>
            <b>Last name</b>
          </Col>
          <Col md={4}>
            <b>Title</b>
          </Col>
        </Row>
        {people.map((p) => createPersonRow(p))}
      </Modal.Body>
      <Modal.Footer style={{ paddingTop: "2px", paddingBottom: "2px" }}>
        <Button
          style={{ fontSize: "15px", width: "100px" }}
          variant="outline-info"
          onClick={closeWin}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMembersModal;
