import React from "react";
import { Col, Row, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import { ImCross } from "react-icons/im";
import AddMembersModal from "../../modals/AddMembersModal";

function MembersSubunitView({
  current,
  members,
  people,
  updateMemberHours,
  addMember,
  deleteMember,
}) {
  const [showAdd, setShowAdd] = React.useState(false);
  console.log(members);

  const createMemberView = (mem) => {
    const person = people.filter((p) => p.id === mem.personID)[0];
    return person ? (
      <Row id="tabContentTableRow" key={person.id}>
        <Col md={3}>{person.firstname + " " + person.lastname}</Col>
        <Col md={3}>{person.title}</Col>
        <Col md={1}>
          <Form.Control
            size="sm"
            type="text"
            id={"hours" + mem.id}
            style={{ maxWidth: "4vw" }}
            defaultValue={mem.workingHours}
            onKeyDown={(e) =>
              e.key === "Enter"
                ? document.getElementById("hours" + mem.id).blur()
                : NaN
            }
            onBlur={(e) => updateMemberHours(mem, e.target.value)}
          />
        </Col>
        <Col md={4}>{person.status}</Col>
        <Col md={1} style={{ paddingLeft: "30px" }}>
          {/** TODO remove from list when clicked */}
          <OverlayTrigger
            onClick={console.log("sd")}
            placement="left"
            overlay={
              <Tooltip
                style={{ fontSize: "0.7rem" }}
                //onClick={deleteMember(person.id)}
              >
                Remove {person.id}
              </Tooltip>
            }
          >
            <ImCross
              id="subunitAddBtn"
              onClick={deleteMember(
                parseInt(
                  members
                    .filter((key) => key.personID == person.id)
                    .map((key) => key.id)
                )
              )}
            />
          </OverlayTrigger>
        </Col>
      </Row>
    ) : (
      <> </>
    );
  };

  return (
    <Row style={{ margin: 0 }}>
      <Col md={12}>
        <Row id="tabContentHeader">
          <Col md={3}>NAME</Col>
          <Col md={3}>TITLE</Col>
          <Col md={1}>HOURS</Col>
          <Col md={4}>STATUS</Col>
          <Col md={1} style={{ paddingLeft: "30px" }}>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip style={{ fontSize: "0.7rem" }}>Add new</Tooltip>
              }
            >
              <GrAddCircle
                id="subunitAddBtn"
                style={{ height: "18px", width: "18px" }}
                onClick={() => setShowAdd(true)}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <div>
          {members
            .filter((m) => m.unitID === current.id)
            .map((fm) => createMemberView(fm))}
        </div>
      </Col>
      <AddMembersModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        title="members"
        closeWin={() => setShowAdd(false)}
        saveFunc={(data) => {
          addMember(data);

          //setShowAdd(false);
        }}
        current={current}
        people={people}
        members={members}
      />
    </Row>
  );
}

export default MembersSubunitView;
