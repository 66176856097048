import React from 'react'
import { Col, Row, Tabs, Tab } from 'react-bootstrap'
import comingsoon from '../../img/comingsoon.png'
import DocumentsSubunitView from '../subunits/decision/DocumentsSubunitView'
import PreparersSubunitView from '../subunits/decision/PreparersSubunitView'
import ReceiversSubunitView from '../subunits/decision/ReceiversSubunitView'
import RecurrencesSubunitView from '../subunits/decision/RecurrencesSubunitView'
import RelatedSubunit from '../subunits/decision/RelatedSubunit'

function DecisionTabsView({decision, decisionList, notes, reccurences, edit, people, receivers, receiverNotes, setCurrent, setNotes, removeNote, 
                        createReccurence, removeReccurence, saveReceivers, updateReceiverNote }) {

    const createRecFunc = (data) => {
        createReccurence(decision.id, data)
    }

    const removeRecFunc = (id) => {
        removeReccurence(decision.id, id)
    }

    const saveReceiverFunc = (arr) => {
        saveReceivers(decision.id, arr)
    }

    const updateReceiverNoteFunc = (personID, note) => {
        updateReceiverNote(decision.id, personID, note)
    }

    return (
        <Row id="decTabs">
            <Tabs fill defaultActiveKey="documents" style={{width:'100%'}} >
                <Tab eventKey="documents" title="DOCUMENTS" id="tabTitle" >
                    <DocumentsSubunitView
                        edit={edit} 
                        notes={notes} 
                        setNotes={setNotes} 
                        decision={decision} 
                        removeNote={removeNote} 
                    />
                </Tab>
                <Tab eventKey="profile" title="PREPARERS" id="tabTitle" >
                    <PreparersSubunitView
                        edit={edit}
                        people={people}
                    />
                </Tab>
                <Tab eventKey="criteria" title="CRITERIA" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                </Tab>
                <Tab eventKey="receivers" title="RECEIVERS" id="tabTitle" >
                    <ReceiversSubunitView
                        edit={edit}
                        people={people}
                        receivers={receivers ? receivers[decision.id] : null}
                        receiverNotes={receiverNotes ? receiverNotes[decision.id] : null}
                        save={saveReceiverFunc}
                        updateNote={updateReceiverNoteFunc}
                    />
                </Tab>
                <Tab eventKey="recurrences" title="RECURRENCES" id="tabTitle" >
                    <RecurrencesSubunitView 
                        edit={edit}
                        reccurences={reccurences ? reccurences[decision.id] : null} 
                        create={createRecFunc} 
                        remove={removeRecFunc}
                    />
                </Tab>
                <Tab eventKey="relatedDecisions" title="RELATED DECISIONS" id="tabTitle" >
                    <RelatedSubunit 
                        decisions={decisionList} 
                        current={decision}
                        setCurrent={setCurrent}
                    />
                </Tab>
                <Tab eventKey="tasks" title="TASKS" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                </Tab>
                <Tab eventKey="messages" title="MESSAGES" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                </Tab>
            </Tabs>
        </Row>
    )

}

export default DecisionTabsView