import React from 'react'
import { Row, Col, Modal, Button } from "react-bootstrap";
import logo from '../../img/logoNoBackground.png'

const AddPeopleModal = ({show, onHide, title, saveFunc, people, receivers }) => {

    const [added, setAdded] = React.useState(receivers)

    const check = (id) => {
        return document.getElementById(id).checked
    }

    const createPeopleRow = (p) => {
        return (
            <Row id="modTabRow" key={p.id} >
                <Col md={1}>
                    <input 
                        id={p.id}
                        type="checkbox" 
                        onChange={() => {
                            if(check(p.id)) setAdded([...added, p.id])
                            else setAdded(added.filter(a => a !== p.id))
                        }} 
                        checked={added && added.includes(p.id)}
                    />
                </Col>
                <Col md={3}>
                    <p style={{color:'gray', fontSize:'12px', textAlign:'center', margin:0}} >{p.firstname}</p>
                </Col>
                <Col md={3}>
                    <p style={{color:'gray', fontSize:'12px', textAlign:'center', margin:0}} >{p.lastname}</p>
                </Col>
                <Col md={5}>
                    <p style={{color:'gray', fontSize:'12px', textAlign:'center', margin:0}} >{p.title}</p>
                </Col>
            </Row>
        )
    }

    return (
        <Modal
            show={show} 
            onHide={onHide}
            centered
            size="lg"
            >
            <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                <Modal.Title>
                    <Row>
                        <Col md={4}>
                            <img src={logo} alt="BasedOn" width="60" />
                        </Col>
                        <Col md={8}>
                            Add {title}
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="modTabs" >
                {/* * TODO Fill with "real" data!
                TODO now only people, not staff or "new"
                <Tabs fill defaultActiveKey="people" style={{width:'100%'}}>
                    <Tab eventKey="staff" title="STAFF" id="tabTitle" >
                        <p style={{color:'gray', fontSize:'12px', textAlign:'center'}} > Nothing to show </p>
                    </Tab>
                    <Tab eventKey="people" title="PEOPLE" id="tabTitle" >
                        {people && people.map(p => createPeopleRow(p))}
                    </Tab>
                    <Tab eventKey="units" title="UNITS" id="tabTitle" >
                        <p style={{color:'gray', fontSize:'12px', textAlign:'center'}} > Nothing to show </p>
                    </Tab>
                    <Tab eventKey="addNew" title="ADD NEW" id="tabTitle" >
                        <Form.Group>
                            <Form.Label>Add a new name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Tab>
                </Tabs> */}
                <div>
                    {people && people.map(p => createPeopleRow(p))}
                </div>
            </Modal.Body>
            <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
            <Button 
                style={{fontSize:'15px', width:'100px'}} 
                variant="outline-info"
                onClick={ () => saveFunc(added)} 
            >
                Save
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPeopleModal