import "../../styles/Decisions.css";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Badge,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaFileDownload, FaRegListAlt, FaPlusSquare } from "react-icons/fa";
import { GiFamilyTree } from "react-icons/gi";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import {
  getDecisionData,
  setCurrentDecision,
  updateDecision,
  addNewDecision,
  deleteDecision,
  getNotes,
  setNotes,
  removeNote,
  createReccurence,
  getReccurences,
  removeReccurence,
  saveReceivers,
  getReceivers,
  updateReceiverNote,
  getReceiverNotes,
} from "../../redux/actions/data";
import DecisionListContainer from "./DecisionListContainer";
import DecisionView from "../views/DecisionView";
import DecisionEditView from "../views/DecisionEditView";
import DecisionDetailModal from "../modals/DecisionDetailModal";
import DecisionTabsView from "../views/DecisionTabsView";
import DecisionAddNewView from "../views/DecisionAddNewView";
import { getFilteredDecisions, getSubUnits } from "../../functions/filterData";

function DecisionContainer({
  decisions,
  currentDecision,
  categories,
  units,
  loading,
  notes,
  matters,
  reccurences,
  people,
  receivers,
  receiverNotes,
  setCurrentDecision,
  updateDecision,
  addNewDecision,
  deleteDecision,
  getNotes,
  setNotes,
  removeNote,
  createReccurence,
  getReccurences,
  removeReccurence,
  saveReceivers,
  getReceivers,
  getReceiverNotes,
  updateReceiverNote,
}) {
  const history = useHistory();

  let userID = JSON.parse(window.localStorage.getItem("UserID"));

  const [edit, setEdit] = React.useState(false);
  const [curIndex, setCurIndex] = React.useState(0);
  const [showExtract, setShowExtract] = React.useState(false);
  const [addNew, setAddNew] = React.useState(false);
  // TODO save starstatus once users are implemented
  const [starFill, setStarFill] = React.useState(false);
  const [nextId, setNextId] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("");

  console.log(sortBy);
  useEffect(() => {
    if (decisions && currentDecision) {
      setCurIndex(decisions.indexOf(currentDecision));
    }
    if (currentDecision && currentDecision.id)
      getReccurences(currentDecision.id);
    if (currentDecision && currentDecision.id) getNotes(currentDecision.id);
    if (currentDecision && currentDecision.id) getReceivers(currentDecision.id);
    if (currentDecision && currentDecision.id)
      getReceiverNotes(currentDecision.id);
  }, [currentDecision]);

  let subUnits = userID && units ? getSubUnits(userID, units) : null;
  const filteredDecisions = getFilteredDecisions(decisions, subUnits, sortBy);

  const addNewBtnFunc = () => {
    const ids = decisions.map((user) => user.id);
    const sorted = ids.sort((a, b) => a - b);
    const maxId = parseInt(sorted[sorted.length - 1]);
    setNextId(String(maxId + 1));
    console.log(nextId);

    setAddNew(!addNew);
    setCurrentDecision(null);
  };

  const backAddNewBtnFunc = () => {
    setAddNew(false);
  };

  const editBtnFunc = () => {
    setEdit(true);
  };

  const backBtnFunc = () => {
    setEdit(false);
  };

  const handleSave = (data) => {
    updateDecision(data);
    setEdit(false);
  };

  const handleDeleteDecision = (id) => {
    deleteDecision(id);
    setEdit(false);
    setCurrentDecision(null);
  };

  const handleSwitch = (index) => {
    setCurIndex(index);
    setCurrentDecision(decisions[index].id);
  };

  const createUpperDecString = (id) => {
    var st = "";
    decisions.forEach((elem) => {
      if (elem.id === id) st = elem.title;
    });
    return st;
  };

  const extractModal =
    loading || !decisions || !currentDecision ? (
      <></>
    ) : (
      <DecisionDetailModal
        show={showExtract}
        onHide={() => setShowExtract(false)}
        current={currentDecision}
        home={false}
        receivers={receivers[currentDecision.id]}
        people={people}
      />
    );
  return (
    <Col id="mainviewcol">
      <Row style={{ height: "3rem", backgroundColor: "lightgrey" }}>
        {/** TODO possibly put Decisions Header in own file */}
        <Col md={2} style={{ fontSize: "1.7rem", paddingTop: "0.3rem" }}>
          DECISIONS
        </Col>
        {currentDecision ? (
          <>
            {
              edit ? (
                <></> //Display arrows if edit is false
              ) : (
                <Col md={{ span: 2, offset: 5 }} id="decArrows">
                  <BsArrowLeft
                    id="arrowBtn"
                    onClick={() => {
                      handleSwitch(
                        curIndex - 1 < 0 ? decisions.length - 1 : curIndex - 1
                      );
                    }}
                  />
                  <Badge
                    pill
                    variant="info"
                    style={{
                      fontSize: "1rem",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      marginLeft: "15px",
                      marginRight: "15px",
                    }}
                  >
                    {curIndex + 1} / {decisions.length}
                  </Badge>
                  <BsArrowRight
                    id="arrowBtn"
                    onClick={() => {
                      handleSwitch((curIndex + 1) % decisions.length);
                    }}
                  />
                </Col>
              ) /** Display View,Extract and tree if not inside edit*/
            }
            <Col
              md={{ span: 3, offset: edit ? 7 : 0 }}
              style={{ textAlign: "center" }}
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>Add new</Tooltip>
                }
              >
                <FaPlusSquare id="decisionIcons" onClick={addNewBtnFunc} />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>View all</Tooltip>
                }
              >
                <FaRegListAlt
                  id="decisionIcons"
                  onClick={() => setCurrentDecision(null)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>Extract</Tooltip>
                }
              >
                <FaFileDownload
                  id="decisionIcons"
                  onClick={() => setShowExtract(true)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>View tree</Tooltip>
                }
              >
                <GiFamilyTree
                  style={{ transform: "rotate(180deg" }}
                  id="decisionIcons"
                  onClick={() =>
                    history.push({
                      pathname: "/tree",
                      state: { type: "decision" },
                    })
                  }
                />
              </OverlayTrigger>
              {/** TODO only show when logged in */}
              {starFill ? (
                <AiFillStar
                  id="decisionIcons"
                  onClick={() => setStarFill(!starFill)}
                />
              ) : (
                <AiOutlineStar
                  id="decisionIcons"
                  onClick={() => setStarFill(!starFill)}
                />
              )}
            </Col>
          </>
        ) : (
          //Add new button if no Current decision is active, currently no use.
          <>
            {" "}
            {!addNew ? (
              <Col md={{ span: 2, offset: 8 }}>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip style={{ fontSize: "12px" }}>Add new</Tooltip>
                  }
                >
                  <FaPlusSquare id="decisionIcons" onClick={addNewBtnFunc} />
                </OverlayTrigger>
              </Col>
            ) : (
              <></>
            )}
          </>
        )}
      </Row>
      {loading || !decisions ? (
        <Col
          md={12}
          className="d-flex justify-content-center"
          style={{ paddingTop: "5%" }}
        >
          <Spinner animation="border" />
        </Col>
      ) : (
        <>
          {decisions && !currentDecision ? (
            <>
              {addNew ? (
                <DecisionAddNewView
                  backBtn={backAddNewBtnFunc}
                  handleSave={handleSave}
                  decisionList={decisions}
                  categories={categories}
                  units={units}
                  matters={matters}
                  createUpperDecString={createUpperDecString}
                  nextId={nextId}
                />
              ) : (
                <Col>
                  <Row
                    md={{ span: 3, offset: edit ? 7 : 0 }}
                    style={{ textAlign: "center" }}
                  >
                    <Col>
                      Sort by:{" "}
                      <Button
                        type="submit"
                        className="mb-2"
                        variant="outline-secondary"
                        size="sm"
                        style={{ width: "5rem", height: "2rem" }}
                        onClick={() => setSortBy("name")}
                      >
                        Name
                      </Button>
                      <Button
                        type="submit"
                        className="mb-2"
                        variant="outline-secondary"
                        size="sm"
                        style={{
                          width: "5rem",
                          height: "2rem",
                        }}
                        onClick={() => setSortBy("category")}
                      >
                        Category
                      </Button>
                      <Button
                        type="submit"
                        className="mb-2"
                        variant="outline-secondary"
                        size="sm"
                        style={{ width: "5rem", height: "2rem" }}
                        onClick={() => setSortBy("matter")}
                      >
                        Matter
                      </Button>
                      <Button
                        type="submit"
                        className="mb-2"
                        variant="outline-secondary"
                        size="sm"
                        style={{ width: "5rem", height: "2rem" }}
                        onClick={() => setSortBy("")}
                      >
                        Default
                      </Button>
                    </Col>
                  </Row>
                  <DecisionListContainer
                    decisions={filteredDecisions}
                    setCurrent={setCurrentDecision}
                  />
                </Col>
              )}
            </>
          ) : (
            <>
              {edit ? (
                <DecisionEditView
                  backBtn={backBtnFunc}
                  handleSave={handleSave}
                  deleteDecision={handleDeleteDecision}
                  decision={currentDecision}
                  decisionList={decisions}
                  categories={categories}
                  units={units}
                  notes={notes}
                  matters={matters}
                  setNotes={setNotes}
                  removeNote={removeNote}
                  createUpperDecString={createUpperDecString}
                  createReccurence={createReccurence}
                  reccurences={reccurences}
                  removeReccurence={removeReccurence}
                  setCurrent={setCurrentDecision}
                />
              ) : (
                <DecisionView
                  id="devView"
                  editBtn={editBtnFunc}
                  decision={currentDecision}
                  createUpperDecString={createUpperDecString}
                />
              )}
              <Row>
                <DecisionTabsView
                  edit={edit}
                  decision={currentDecision}
                  decisionList={decisions}
                  notes={notes}
                  people={people}
                  reccurences={reccurences}
                  receivers={receivers}
                  receiverNotes={receiverNotes}
                  setNotes={setNotes}
                  removeNote={removeNote}
                  createReccurence={createReccurence}
                  removeReccurence={removeReccurence}
                  setCurrent={setCurrentDecision}
                  saveReceivers={saveReceivers}
                  updateReceiverNote={updateReceiverNote}
                />
              </Row>
            </>
          )}
          {extractModal}
        </>
      )}
    </Col>
  );
}

export default connect(
  (state) => {
    return {
      decisions: state.data.decisions,
      currentDecision: state.data.currentDecision,
      currentUnit: state.data.currentUnit,
      categories: state.data.categories,
      units: state.data.units,
      loading: state.data.loading,
      notes: state.data.notes,
      matters: state.data.matters,
      reccurences: state.data.reccurences,
      people: state.data.people,
      receivers: state.data.receivers,
      receiverNotes: state.data.receiverNotes,
    };
  },
  (dispatch) => {
    return {
      setCurrentDecision: (id) => dispatch(setCurrentDecision(id)),
      updateDecision: (data) => dispatch(updateDecision(data)),
      addNewDecision: (data) => dispatch(addNewDecision(data)),
      deleteDecision: (id) => dispatch(deleteDecision(id)),
      getDecisionData: () => dispatch(getDecisionData()),
      getNotes: (id) => dispatch(getNotes(id)),
      setNotes: (id, data) => dispatch(setNotes(id, data)),
      removeNote: (decID, noteID) => dispatch(removeNote(decID, noteID)),
      createReccurence: (decID, data) =>
        dispatch(createReccurence(decID, data)),
      getReccurences: (decID) => dispatch(getReccurences(decID)),
      removeReccurence: (decID, recID) =>
        dispatch(removeReccurence(decID, recID)),
      saveReceivers: (decisionID, receiverArr) =>
        dispatch(saveReceivers(decisionID, receiverArr)),
      getReceivers: (decisionID) => dispatch(getReceivers(decisionID)),
      getReceiverNotes: (decisionID) => dispatch(getReceiverNotes(decisionID)),
      updateReceiverNote: (decisionID, personID, note) =>
        dispatch(updateReceiverNote(decisionID, personID, note)),
    };
  }
)(DecisionContainer);
