import React from "react";
import {
  Col,
  Row,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import { FaFileDownload, FaRegListAlt } from "react-icons/fa";
import PeopleView from "../views/PeopleView";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import PeopleTabsView from "../views/PeopleTabsView";
import {
  getUnitData,
  savePersonAddress,
  updatePerson,
} from "../../redux/actions/data";

function PeopleContainer({
  people,
  units,
  unitMembers,
  loading,
  updatePerson,
  savePersonAddress,
}) {
  const [current, setCurrent] = React.useState(null);
  const [curIndex, setCurIndex] = React.useState(0);
  const [addNew, setAddNew] = React.useState(false);

  const changeCurrent = (personID) => {
    setCurrent(people.filter((p) => p.id === personID)[0]);
  };

  const handleSwitch = (index) => {
    console.log(current);
    setCurIndex(index);
    setCurrent(people.filter((p) => p.id === people[index].id)[0]);
  };

  const getUnit = (id) => {
    const tmp = unitMembers ? [...unitMembers] : [];
    const temop = tmp.filter((key) => key.personID == id);

    const keys = temop.map((key) => key.unitID);
    const tmpUnits = units.filter((key) => key.id == keys);
    console.log(tmpUnits.map((key) => key.name));
    if (temop.map((key) => key.unitID)) {
      return tmpUnits.map((key) => key.name);
    }
  };
  return (
    <Col id="mainviewcol">
      <Row style={{ height: "3rem", backgroundColor: "lightgrey" }}>
        <Col md={2} style={{ fontSize: "1.7rem", paddingTop: "0.3rem" }}>
          PEOPLE
        </Col>
        {current ? (
          <>
            <Col md={{ span: 2, offset: 5 }} id="decArrows">
              <BsArrowLeft
                id="arrowBtn"
                onClick={() => {
                  handleSwitch(
                    curIndex - 1 < 0 ? people.length - 1 : curIndex - 1
                  );
                }}
              />
              <Badge
                pill
                variant="info"
                style={{
                  fontSize: "1rem",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                {curIndex + 1} / {people.length}
              </Badge>
              <BsArrowRight
                id="arrowBtn"
                onClick={() => {
                  handleSwitch((curIndex + 1) % people.length);
                }}
              />
            </Col>
            <Col md={{ span: 2, offset: 1 }} style={{ paddingLeft: "60px" }}>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>View all</Tooltip>
                }
              >
                <FaRegListAlt
                  id="decisionIcons"
                  onClick={() => setCurrent(null)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip style={{ fontSize: "12px" }}>Extract</Tooltip>
                }
              >
                <FaFileDownload id="decisionIcons" />
              </OverlayTrigger>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
      {loading || !people ? (
        <Col
          md={12}
          className="d-flex justify-content-center"
          style={{ paddingTop: "5%" }}
        >
          <Spinner animation="border" />
        </Col>
      ) : current ? (
        <>
          <PeopleView
            person={current}
            update={updatePerson}
            unitMembers={unitMembers}
          />
          <PeopleTabsView
            people={people}
            current={current}
            units={units}
            unitMembers={unitMembers}
            savePersonAddress={savePersonAddress}
          />
        </>
      ) : (
        <Col md={12} style={{ marginTop: "20px" }}>
          {people.map((p) => (
            <Row>
              <div
                id="decListItems"
                className="w-100 pl-3 pr-3 pt-2 pb-0"
                key={p.id}
                onClick={() => changeCurrent(p.id)}
              >
                <Row>
                  <Col>
                    <h2 style={{ fontSize: 22 }}>
                      {p.firstname + " " + p.lastname}
                    </h2>
                    <p style={{ fontSize: 18 }}>{p.title}</p>
                  </Col>
                  <Col style={{ textAlign: "center" }}>
                    <p
                      className="mt-1 mb-2"
                      style={{ textAlign: "end", fontSize: 16 }}
                    >
                      {getUnit(p.id)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Row>
          ))}
        </Col>
      )}
    </Col>
  );
}

export default connect(
  (state) => {
    return {
      people: state.data.people,
      units: state.data.units,
      unitMembers: state.data.unitMembers,
      loading: state.data.loading,
    };
  },
  (dispatch) => {
    return {
      updatePerson: (personID, data) => dispatch(updatePerson(personID, data)),
      savePersonAddress: (personID, address) =>
        dispatch(savePersonAddress(personID, address)),
    };
  }
)(PeopleContainer);
