import React from 'react'
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { GrAddCircle } from 'react-icons/gr'
import { ImCross } from 'react-icons/im'
import UnitMeetingModal from '../../modals/UnitMeetingModal'

function MeetingSubunitView({meetings, createMeeting, removeMeeting }) {

    const [show, setShow] = React.useState(false)

    const createMeetingRow = (key) => {
        return (
            <Row id="tabContentTableRow" key={key} >
                <Col md={3}>
                    {meetings[key].title}
                </Col>
                <Col md={4}>
                    {meetings[key].location}
                </Col>
                <Col md={2}>
                    {meetings[key].dateTime}
                </Col>
                <Col md={2}>
                    {meetings[key].duration}
                </Col>
                <Col md={1} style={{paddingLeft:'30px'}} >
                    <ImCross id="subunitAddBtn" onClick={() => removeMeeting(key)} />
                </Col>
            </Row>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={3}>
                        TITLE
                    </Col>
                    <Col md={4}>
                        LOCATION
                    </Col>
                    <Col md={2}>
                        TIME
                    </Col>
                    <Col md={2}>
                        DURATION
                    </Col>
                    <Col md={1} style={{paddingLeft:'30px'}} >
                        <OverlayTrigger placement="left" overlay={<Tooltip style={{fontSize:'0.7rem'}} >Add new</Tooltip>} > 
                            <GrAddCircle id="subunitAddBtn" style={{height:'18px', width:'18px'}} onClick={() => setShow(true)} />
                        </OverlayTrigger>
                    </Col>
                </Row>
                <div id="tabContentTable" >
                    {meetings && Object.keys(meetings).map(key => createMeetingRow(key))}
                </div>
            </Col>
            <UnitMeetingModal show={show} onHide={() => setShow(false)} saveFunc={(data) => createMeeting(data)} />
        </Row>
    )
}

export default MeetingSubunitView