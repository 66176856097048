import React, { useEffect } from 'react'
import { Col, Row, Card, Modal, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { GrAddCircle } from 'react-icons/gr'
import { ImCross } from 'react-icons/im'

function DocumentsSubunitView({ edit, notes, setNotes, decision, removeNote }) {

    const [docAdd, setDocAdd] = React.useState(false)
    const [notesAdd, setNotesAdd] = React.useState(false)
    const [addFile, setAddFile] = React.useState(null)
    const [addFileName, setAddFileName] = React.useState("")
    const [addNote, setAddNote] = React.useState("")
    const [curNotes, setCurNotes] = React.useState(null)

    useEffect(() => {
        setCurNotes(notes[decision.id])
    }, [notes, decision.id])
    
    const createNote = (key) => {
        return (
            <Card key={key} >
                <Card.Body> 
                    <Row style={{margin:0}} >
                        <Col md={11}>
                            {curNotes[key].note} 
                        </Col>
                        {edit ? (
                            <Col md={1}>
                                <ImCross id="noteRemoveBtn" onClick={() => removeNote(decision.id, key)} />
                            </Col>
                        ) : ''}
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md="6" id="docCard"  >
                <Card border="info" >
                    <Card.Header>
                        <Row>
                            <Col md={11} >Documents</Col>
                            {edit ? (
                                <Col md={1}>
                                    <OverlayTrigger placement="left" overlay={<Tooltip style={{fontSize:'10px'}} >Add documents</Tooltip>} > 
                                        <GrAddCircle id="subunitAddBtn" onClick={() => setDocAdd(true)} />
                                    </OverlayTrigger> 
                                </Col>
                            ) : ''}
                        </Row>
                        </Card.Header>
                    <Card.Body>
                    <p style={{color:'gray'}}> There are no documents related to this decision </p>
                    {/** Not possible to save files in this database, this should possibly be removed */}
                    <p style={{color:'gray'}}> It is not yet possible to upload and save files </p>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" id="docCard"  >
                <Card border="info" >
                    <Card.Header>
                        <Row>
                            <Col md={11} >Notes</Col>
                            {edit ? (
                                <Col md={1}>
                                    <OverlayTrigger placement="left" overlay={<Tooltip style={{fontSize:'10px'}} >Add notes </Tooltip>} > 
                                        <GrAddCircle id="docCardAddBtn" onClick={() => setNotesAdd(true)} />
                                    </OverlayTrigger> 
                                </Col>
                            ): ''}
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {(!curNotes) ? "" : Object.keys(curNotes).map(key => createNote(key))}
                    </Card.Body>
                </Card>
            </Col>
            <Modal show={docAdd} onHide={() => {setDocAdd(false); setAddFileName('')}} centered >
                <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                    <Modal.Title as="h5" >Add documents </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{fontSize:'12px', color:'var(--dark-gray)'}}>You can only add one file at a time </p>
                    <Form>
                        <Form.File 
                            label="Choose your file"
                            custom 
                            onChange={(e) => {setAddFile(e.target.files[0]); setAddFileName(e.target.files[0].name)}} 
                        />
                    </Form>
                    <p style={{color:'gray'}} >Chosen file: {addFileName} - {addFile}</p>
                </Modal.Body>
                <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
                    <Button 
                        style={{fontSize:'15px', padding:'3px 5px'}} 
                        variant="outline-secondary" 
                        onClick={() => {
                            setDocAdd(false);
                        }} 
                    >
                        Save
                    </Button>
                </Modal.Footer>

            </Modal>
            <Modal show={notesAdd} onHide={() => setNotesAdd(false)} centered >
                <Modal.Header closeButton style={{paddingTop:'7px', paddingBottom:'2px'}}>
                    <Modal.Title as="h5" >Add a note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Control as="textarea" rows="3" value={addNote} onChange={e => setAddNote(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{paddingTop:'2px', paddingBottom:'2px'}} >
                    <Button 
                        style={{fontSize:'15px', padding:'3px 5px'}} 
                        variant="outline-secondary" 
                        onClick={() => {setNotesAdd(false); setNotes(decision.id, addNote)}} 
                    >
                        Save
                    </Button>
                </Modal.Footer>

            </Modal>
        </Row>
    )
}

export default DocumentsSubunitView