import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { GrAddCircle } from 'react-icons/gr'
import AddPeopleModal from '../../modals/AddPeopleModal'


function PreparersSubunitView({edit, people, save}) {

    const [showAdd, setShowAdd] = React.useState(false)

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={3}>
                        NAME
                    </Col>
                    <Col md={2}>
                        ASSIGN DATE
                    </Col>
                    <Col md={1}>
                        STATUS
                    </Col>
                    <Col md={5}>
                        NOTE
                    </Col>
                    {edit ? (
                        <Col md={1} style={{paddingLeft:'30px'}} >
                            <GrAddCircle id="subunitAddBtn" style={{height:'18px', width:'18px'}} onClick={() => setShowAdd(true)} />
                        </Col>
                    ) : ''}
                </Row>
                <Row id="tabContentTableRow" >
                    {/** TODO, this is dummy data */}
                    <Col md={3}>
                        Agnes Forsberg
                    </Col>
                    <Col md={2}>
                        2021-04-22
                    </Col>
                    <Col md={1}>
                        Assigned
                    </Col>
                    <Col md={5}>
                        Developer of this website
                    </Col>
                </Row>
            </Col>
            <AddPeopleModal 
                show={showAdd} 
                onHide={() => setShowAdd(false)} 
                title="preparers" 
                /** TODO add save func to database */
                saveFunc={(prepArr) => {save(prepArr); setShowAdd(false)}} 
                people={people} />
        </Row>
    )

}

export default PreparersSubunitView