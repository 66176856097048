export function decisionJson(decisions, units, current) {
  const uppDecChildren =
    current.upperDecisionID === "No upper decision"
      ? undefined
      : [
          {
            name:
              current.upperDecisionID +
              " - " +
              decisions.filter((d) => d.id === current.upperDecisionID)[0]
                .title,
            attributes: {
              url: "/decisions/" + current.upperDecisionID,
              type: "decision",
              id: current.upperDecisionID,
              data: decisions.filter(
                (d) => d.id === current.upperDecisionID
              )[0],
            },
          },
        ];

  const catChildren =
    current.category.name === "No category"
      ? undefined
      : [
          {
            name: current.category.name,
            attributes: {
              type: "category",
              id: current.category.id,
            },
          },
        ];

  const matChildren =
    current.matter.name === "No matter"
      ? undefined
      : [
          {
            name: current.matter.name,
            attributes: {
              type: "matter",
              id: current.matter.id,
            },
          },
        ];
  console.log(matChildren);

  const subDecChildrenFunc = () => {
    var arr = [];
    decisions.forEach((d) => {
      if ("" + d.upperDecisionID === current.id) {
        arr.push({
          name: d.id + " - " + d.title,
          attributes: {
            url: "/decisions/" + d.id,
            type: "decision",
            id: d.id,
            data: decisions.filter((d) => d.upperDecisionID === current.id),
          },
        });
      }
    });
    return arr.length === 0 ? undefined : arr;
  };

  const subDecChildren = subDecChildrenFunc();

  const decData = {
    name: current.title,
    children: [
      {
        name: "Decision unit",
        children: [
          {
            name: current.ownerUnit.name,
            attributes: {
              type: "unit",
              id: current.ownerUnit.id,
              data: units.filter((u) => u.id === current.ownerUnit.id)[0],
            },
          },
        ],
      },
      {
        name: "Upper decision",
        children: uppDecChildren,
      },
      {
        name: "Sub-decision",
        children: subDecChildren,
      },
      {
        name: "Category",
        children: catChildren,
      },
      {
        name: "Matter",
        children: matChildren,
      },
      {
        name: "Receivers",
        children: [
          {
            name: "TODO",
            attributes: {
              type: "person",
            },
          },
        ],
      },
      {
        name: "Actions",
      },
    ],
  };

  return decData;
}
