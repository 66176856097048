import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoMdOpen } from 'react-icons/io'

function Reccurences({reccurences, decisions, viewDecision }) {

    const htmlRegex = /<[a-z/]*>/g

    const getNextRec = (id) => {
        if(reccurences && reccurences[id]){
            const today = new Date();
            const closest = Object.keys(reccurences[id]).reduce((a, b) => {
                const adiff = new Date(reccurences[id][a].date) - today;
                return adiff > 0 && adiff < new Date(reccurences[id][b].date) - today ? a : b;
            })
            const closestRec = reccurences[id][closest]
            if(new Date(closestRec.date) >= today) {
                return reccurences[id][closest].date
            }
            else return null
        }
        else return null
    }

    const getSortedDecisions = () => {
        var decTmp = decisions
        decTmp.sort((a, b) => {
            const aRec = getNextRec(a.id)
            const bRec = getNextRec(b.id)

            if(aRec && !bRec) return -1
            if(!aRec && bRec) return 1
            if(!aRec && !bRec) return 0
            else return new Date(aRec) - new Date(bRec)
        })

        return decTmp.map(d => createReccurenceRow(d))
    }

    const createReccurenceRow = (dec) => {
        const nextRec = getNextRec(dec.id)

        return (
            <Row key={dec.id} id="tabContentTableRow">
                <Col md={3}>
                    {(dec.title.length >= 100) ? dec.title.substring(0,100) + '...' : dec.title}
                </Col>
                <Col md={5}>
                    <div dangerouslySetInnerHTML={{__html:(dec.decisionText.length > 100 ? dec.decisionText.replaceAll(htmlRegex,'').substring(0,100)+'...' : dec.decisionText.replaceAll(htmlRegex,''))}}/>
                </Col>
                <Col md={3}>
                    {nextRec ? nextRec : 'No future reccurences'}
                </Col>
                <Col md={1}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'18px', height:'18px'}} onClick={() => viewDecision(dec.id)} />
                </Col>

            </Row>
        )
    }

    return (
        <>
        <Row id="tabContentHeader" >
            <Col md={3}>
                ISSUE
            </Col>
            <Col md={5}>
                DECISION
            </Col>
            <Col md={3}>
                NEXT RECCURENCE
            </Col>
            <Col md={1}>
                VIEW
            </Col>
        </Row>
        <div>
            {getSortedDecisions()}
        </div>
        
        </>
    )

}

export default Reccurences