import {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_NOTES,
    FETCH_DOCUMENTS,
    FETCH_PEOPLE,
    FETCH_UNIT_MEMBERS,
    FETCH_MEETINGS,
    FETCH_RECCURENCES,
    FETCH_RECEIVERS,
    FETCH_RECEIVERNOTES,
    SET_CURRENT_DECISION,
    SET_CURRENT_UNIT,
    UPDATE_DECISION,
    UPDATE_MEETING,
    UPDATE_MATTERS,
    UPDATE_RECCURENCES,
    UPDATE_RECEIVERS,
    UPDATE_PEOPLE

    
} from '../actions/data'

//Reducer, describes how actions transform state to the next state
//Based on the action, will modify the store

//Dispatch Execute action. Send to reducer, reducer checks what to do, and the updates state

const initialState = {
    decisions: null,
    information: null,
    units: [],
    categories: [],
    loading: false,
    currentDecision: null,
    currentUnit: null,
    notes: {},
    meetings: {},
    matters: {},
    documents: {},
    people: null,
    unitMembers: null,
    reccurences: {},
    receivers: {},
    receiverNotes: {},
    prepareres: {}
}

const dataReducer = (state=initialState, action) => {

    switch(action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SUCCESS:
            let [decisionData, infoData, categoryData, unitData, matterData] = [state.decisions, state.information, state.categories, state.units, state.matters]
            if(action.payload.decisions){
                let temp = action.payload.decisions
                decisionData = []
                Object.keys(temp).map(dataKey => decisionData.push(produceDecisionData(dataKey, temp[dataKey], state.units, state.categories, state.matters)))
            }
            if(action.payload.information){
                let temp = action.payload.information
                infoData = []
                Object.keys(temp).map(dataKey => infoData.push(produceInfoData(dataKey, temp[dataKey], state.units, state.categories)))
            }
            if(action.payload.categories){
                let temp = action.payload.categories
                categoryData = []
                Object.keys(temp).map(dataKey => categoryData.push({
                    id: dataKey,
                    category: temp[dataKey].category, 
                    description: temp[dataKey].description 
                    }))
            }
            if(action.payload.units){
                let temp = action.payload.units
                unitData = []
                Object.keys(temp).map(dataKey => unitData.push(produceUnitData(dataKey, temp[dataKey])))
            }
            if(action.payload.matters){
                matterData = action.payload.matters
            }
            return {
                ...state,
                decisions: decisionData,
                information: infoData,
                categories: categoryData,
                units: unitData,
                matters: matterData,
                loading: false
            }
        case FETCH_NOTES:
            var notesTmp = state.notes
            notesTmp[action.payload.id] = action.payload.notes
            return {
                ...state,
                notes: notesTmp,
                loading: false
            }
        case FETCH_DOCUMENTS:
            var docTmp = state.documents
            docTmp[action.payload.id] = action.payload.files
            return {
                ...state,
                documents: docTmp,
                loading: false
            }
        case FETCH_PEOPLE:
            var peopleTmp = action.payload
            var peopleData = []
            Object.keys(peopleTmp).map(key => peopleData.push({
                id: key,
                firstname: peopleTmp[key].firstName ? peopleTmp[key].firstName : null,
                lastname: peopleTmp[key].lastName ? peopleTmp[key].lastName : null,
                gender: peopleTmp[key].gender ? peopleTmp[key].gender : null,
                status: peopleTmp[key].status ? peopleTmp[key].status : null,
                title: peopleTmp[key].title ? peopleTmp[key].title : null,
                totalWorkingHours: peopleTmp[key].totalWorkingHours,
                education: peopleTmp[key].education ? peopleTmp[key].education : null,
                phone: peopleTmp[key].phone ? peopleTmp[key].phone : null,
                email: peopleTmp[key].email ? peopleTmp[key].email : null,
                office: peopleTmp[key].office ? peopleTmp[key].office : null,
                website: peopleTmp[key].website ? peopleTmp[key].website : null
            }))
            return {
                ...state,
                people: peopleData
            }
        case FETCH_UNIT_MEMBERS:
            var membersTmp = action.payload
            var memberData = []
            Object.keys(membersTmp).map(key => memberData.push({
                id: key,
                personID: membersTmp[key].personID,
                unitID: membersTmp[key].unitID, 
                startDate: membersTmp[key].start,
                status: membersTmp[key].status,
                workingHours: membersTmp[key].workingHours
            }))
            return {
                ...state,
                unitMembers: memberData
            }
        case FETCH_MEETINGS:
            var meetingsTmp = state.meetings
            if(action.payload.meetings && action.payload.unit) meetingsTmp[action.payload.unit] = action.payload.meetings
            else if(action.payload.meetings) meetingsTmp = action.payload.meetings
            return {
                ...state,
                meetings: meetingsTmp,
                loading: false
            }
        case FETCH_RECCURENCES:
            var recTmp = state.reccurences
            if(action.payload.dec) recTmp[action.payload.dec] = action.payload.recs
            else recTmp = action.payload.recs
            return {
                ...state,
                reccurences: recTmp,
                loading: false
            }
        case FETCH_RECEIVERS:
            var receTmp = state.receivers
            receTmp[action.payload.decID] = action.payload.receivers
            return {
                ...state,
                receivers: receTmp,
                loading:false
            }
        case FETCH_RECEIVERNOTES:
            var recNotesTmp = state.receiverNotes
            recNotesTmp[action.payload.decID] = action.payload.notes
            return {
                ...state,
                receiverNotes: recNotesTmp,
                loading: false
            }
        case UPDATE_MEETING:
            var meetingTmp = state.meetings
            if(action.payload.remove) delete meetingTmp[action.payload.unitID][action.payload.meetingID]
            else meetingTmp[action.payload.unitID][action.payload.meetingID] = action.payload.meetingData
            return {
                ...state,
                meetings: meetingTmp,
                loading:false
            }
        case UPDATE_MATTERS:
            var mattersTmp = state.matters
            if(action.payload.remove) delete mattersTmp[action.payload.unitID][action.payload.matterID]
            else mattersTmp[action.payload.unitID][action.payload.matterID] = action.payload.matterData
            return {
                ...state,
                matters: mattersTmp,
                loading: false
            }
        case UPDATE_RECCURENCES:
            var reccTmp = state.reccurences
            if(action.payload.remove) delete reccTmp[action.payload.decID][action.payload.recID]
            else reccTmp[action.payload.decID][action.payload.recID] = action.payload.recData
            return {
                ...state,
                reccurences: reccTmp,
                loading: false
            }
        case UPDATE_RECEIVERS:
            var receiversTmp = state.receivers
            receiversTmp[action.payload.decID] = action.payload.data
            return {
                ...state,
                receivers: receiversTmp,
                loading: false
            }
        case UPDATE_PEOPLE:
            var pTmp = state.people
            pTmp[action.payload.personID] = action.payload.data
            return {
                ...state,
                people: pTmp,
                loading: false
            }
        case SET_CURRENT_DECISION:
            var decTmp = null
            state.decisions.forEach(element => {
                if(element.id === action.payload.decID) decTmp = element
            })
            return {
                ...state,
                currentDecision: decTmp
            }
        case SET_CURRENT_UNIT:
            var unitTmp = state.units.filter(u => u.id === action.payload.unitID)[0]
            return {
                ...state,
                currentUnit: unitTmp
            }
        case UPDATE_DECISION:
            var tmp = state.decisions
            var tmpID = tmp.findIndex((elem) => elem.id === action.payload.id)
            tmp[tmpID] = produceDecisionData(action.payload.id, action.payload.data, state.units, state.categories, state.matters)
            return {
                ...state,
                decisions: tmp,
                currentDecision: tmp[tmpID]
            }
        default:
            return state
    }
}

const produceDecisionData = (id, data, units, categories, matters) => {

    let ownerUnit = {name: 'No owner unit', id: null}
    let category = {name: 'No category', id: null}
    let matter = {name: 'No matter', id:null}

    if(data.ownerUnit !== undefined) {
        units.forEach(elem => {
            if(elem.id === data.ownerUnit) ownerUnit = {name: elem.name, id: data.ownerUnit}
        })
    }
    if(data.categoryID !== undefined) {
        categories.forEach(elem => {
            if(elem.id === data.categoryID) category = {name: elem.category, id: data.categoryID}
        })
    }
    if(data.matterID){
        Object.keys(matters).forEach(unit => Object.keys(matters[unit]).forEach(m => {
            if(m === data.matterID) matter = { name: matters[unit][m].matter, id: data.matterID }
        }))
    }

    const setData = {
        id: id,
        title: (data.decisionTitle === undefined) ? 'No title' : data.decisionTitle,
        decisionText: (data.decisionText === undefined) ? 'No decision' : data.decisionText,
        basis: (data.basis === undefined) ? 'No basis' :data.basis,
        category: category,
        creationDate: (data.creationDate === undefined) ? null : data.creationDate,
        createdBy: (data.createdBy === undefined) ? null : data.createdBy,
        decisionDate: (data.decisionDate === undefined) ? null : data.decisionDate,
        decisionLevel: (data.decisionLevel === undefined) ? 'No level' : data.decisionLevel,
        // TODO check what default for these 2 should be
        isDecided: (data.isDecided === undefined) ? false : data.isDecided,
        isPublic: (data.isPublic) ? true : data.isPublic,
        lastModifiedDate: (data.lastModifiedDate === undefined) ? 'XXXX-XX-XX' : data.lastModifiedDate,
        lastModifier: (data.lastModifier === undefined) ? 'Unknown' : data.lastModifier,
        ownerUnit: ownerUnit,
        referenceNr: (data.referenceNr === undefined) ? 'No reference number' : data.referenceNr,
        matter: matter,
        validFrom: (data.validFrom === undefined) ? null : data.validFrom,
        validTo: (data.validTo === undefined) ? null : data.validTo,
        upperDecisionID: (data.upperDecisionID === undefined) ? 'No upper decision' : data.upperDecisionID,
        keywords: (data.keywords === undefined) ? [] : data.keywords,
        direction: (!data.direction) ? null : data.direction
    }

    return setData

}

const produceInfoData = (id, data, units, categories) => {

    let ownerUnit = 'No owner unit'
    let category = 'No category'

    if(data.ownerUnit !== undefined) {
        units.forEach(elem => {
            if(parseInt(elem.id) === data.ownerUnit) ownerUnit = elem.name
        })
    }
    if(data.categoryID !== undefined) {
        categories.forEach(elem => {
            if(parseInt(elem.id) === data.categoryID) category = elem.category
        })
    }

    const setData = {
        id: id,
        title: (data.title === undefined) ? 'No title' : data.title,
        infoText: (data.infoText === undefined) ? 'No information' : data.infoText,
        referenceNr: (data.referenceNr === undefined) ? 'No reference number' : data.referenceNr,
        ownerUnit: ownerUnit,
        category: category,
        matterID: (data.matterID === undefined) ? 'No matter' : data.matterID,
        infoLevel: (data.infoLevel === undefined) ? 'No level' : data.infoLevel,
        infoDate: (data.infoDate === undefined) ? null : data.infoDate,
        basis: (data.basis === undefined) ? 'No basis' : data.basis,
        createdBy: (data.createdBy === undefined) ? 'Unknown' : data.createdBy,
        creationDate: (data.creationDate === undefined) ? null : data.creationDate,
        lastModifiedDate: (data.lastModifiedDate === undefined) ? null : data.lastModifiedDate,
        lastModifier: (data.lastModifier === undefined) ? 'Unknown' : data.lastModifier,
    }

    return setData
}

const produceUnitData = (id, data) => {

    return {
        id: id,
        name: (data.nameEng) ? data.nameEng : 'No name',
        description: (data.description) ? data.description : '',
        status: (data.status) ? data.status : 'No status',
        type: (data.type) ? data.type : 'No type',
        superUnitID: (data.superUnitID) ? data.superUnitID : null,
        shortName: (data.shortName) ? data.shortName : '',
        startDate: (data.startDate) ? data.startDate : null,
        endDate: (data.finishDate) ? data.endDate : null,
        createdBy: (data.createdBy) ? data.createdBy: 'Unknown',
        creationDate: (data.creationDate) ? data.creationDate: null,
        lastModifiedDate: (data.lastModifiedDate === undefined) ? null : data.lastModifiedDate,
        lastModifier: (data.lastModifier === undefined) ? 'Unknown' : data.lastModifier,
    }
}

export default dataReducer