export function categoryJson(decisions, units, current) {

    const description = current.description === 'None' ? undefined : [ { name: current.description } ]

    const getCategoryDecisions = () => {
        var arr = []
        const filteredDec = decisions.filter(d => d.category.id === current.id)
        filteredDec.forEach(fd => {
            arr.push({
                name: fd.title,
                attributes: {
                    type: 'decision',
                    id: fd.id
                }
            })
        })

        return arr
    }

    const catDec = getCategoryDecisions()

    const categoryData = {
        name: current.category,
            children: [
                {
                    name: 'Description',
                    children: description
                },
                {
                    name: 'Decisions',
                    children: catDec
                }
                
            ]
        
    }

    return categoryData
}