import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import { Container, Row } from "react-bootstrap";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Containers
import HeaderContainer from "./layout/containers/HeaderContainer";
import HomeContainer from "./layout/containers/HomeContainer";
import TraceContainer from "./layout/containers/TraceContainer";
import DecisionContainer from "./layout/containers/DecisionContainer";
import InformationEditContainer from "./layout/containers/InformationEditContainer";
import ReceivedContainer from "./layout/containers/ReceivedContainer";
import TaskContainer from "./layout/containers/TaskContainer";
import MeetingContainer from "./layout/containers/MeetingContainer";
import PeopleContainer from "./layout/containers/PeopleContainer";
import UnitContainer from "./layout/containers/UnitContainer";
import TreeView from "./layout/views/TreeView";
import {
  getPeople,
  getCategoryData,
  getDecisionData,
  getInfoData,
  getUnitData,
  getUnitMembers,
  getMatters,
} from "./redux/actions/data";

function App() {
  loadState();

  const paths = {
    home: "/",
    trace: "/trace",
    decisions: "/decisions",
    information: "/information",
    received: "/received",
    task: "/tasks",
    meeting: "/meetings",
    people: "/people",
    unit: "/units",
    tree: "/tree",
  };
  //Textcommit
  return (
    <Provider store={store}>
      <Container id="app">
        <HeaderContainer />
        <Row id="home-main-body">
          <Router>
            <Switch>
              <Route path={paths.trace}>
                <TraceContainer />
              </Route>
              <Route path={paths.decisions}>
                <DecisionContainer />
              </Route>
              <Route path={paths.information}>
                <InformationEditContainer />
              </Route>
              <Route path={paths.received}>
                <ReceivedContainer />
              </Route>
              <Route path={paths.task}>
                <TaskContainer />
              </Route>
              <Route path={paths.meeting}>
                <MeetingContainer />
              </Route>
              <Route path={paths.people}>
                <PeopleContainer />
              </Route>
              <Route path={paths.unit}>
                <UnitContainer />
              </Route>
              <Route path={paths.tree}>
                <TreeView />
              </Route>
              <Route path={paths.home}>
                <HomeContainer />
              </Route>
            </Switch>
          </Router>
        </Row>
      </Container>
    </Provider>
  );
}

function loadState() {
  store.dispatch(getUnitData());
  store.dispatch(getCategoryData());
  store.dispatch(getMatters());
  store.dispatch(getDecisionData());
  store.dispatch(getInfoData());
  store.dispatch(getPeople());
  store.dispatch(getUnitMembers());
}

export default App;
