import React from 'react'
import { Col, Row, Tabs, Tab } from 'react-bootstrap'
import comingsoon from '../../img/comingsoon.png'
import PeopleUnitsSubview from '../subunits/people/PeopleUnitsSubview'
import AddressSubunit from '../subunits/people/AddressSubunit'

function PeopleTabsView({people, current, units, unitMembers, savePersonAddress }) {

    return (
        <Row >
            <Col md={12} >
                <Tabs fill defaultActiveKey="units" style={{width:'100%'}} >
                    <Tab eventKey="units" title="IN UNITS" id="tabTitle" >
                        <PeopleUnitsSubview 
                            units={units}
                            unitMembers={unitMembers}
                            current={current}
                        />
                    </Tab>
                    <Tab eventKey="actions" title="IN ACTIONS" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                    </Tab>
                    <Tab eventKey="meetings" title="MEETINGS" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                    </Tab>
                    <Tab eventKey="position" title="POSITION" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                    </Tab>
                    <Tab eventKey="address" title="ADDRESS" id="tabTitle" >
                        <AddressSubunit current={current} save={savePersonAddress} />
                    </Tab>
                    <Tab eventKey="notes" title="NOTES" id="tabTitle" >
                    <Col md={{offset:4, span:6}}>
                        <img src={comingsoon} alt="coming soon"/>
                    </Col>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    )

}

export default PeopleTabsView