import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import RichTextEditor from 'react-rte'

function DecisionTexteditorModal({show, onHide, modalMode, setChanged, setDecText, setBasis, modalRichValue, setModalRichValue }) {

    const [modalValue, setModalValue] = React.useState('')
    const [editorChanged, setEditorChanged] = React.useState(false)

    const onEditorChange = (content) => {
        setEditorChanged(true)
        setModalRichValue(content)
        setModalValue(content.toString('html'))
    }

    const handleEditorModalClose = (toSave) => {
        if(modalMode === 'decText' && toSave && editorChanged) {
            setChanged(true)
            setDecText(modalValue)
            setEditorChanged(false)
        }
        else if(modalMode === 'basis' && toSave && editorChanged) {
            setChanged(true)
            setBasis(modalValue)
            setEditorChanged(false)
        }
        onHide()
    }

    return (
        <Modal 
            size="lg"
            show={show} 
            onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit {(modalMode === 'decText') ? 'Decision' : 'Basis'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight:'75vh', overflowY:'auto', minHeight:'50vh'}} >
                <RichTextEditor
                    value={modalRichValue}
                    onChange={onEditorChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => handleEditorModalClose(false)}>
                    Close and discard changes
                </Button>
                <Button variant="secondary" onClick={() => handleEditorModalClose(true)}>
                    Close and keep changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DecisionTexteditorModal