import { db } from "../../firebase/firebase";

/**
 * Action types
 */
export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_NOTES = "FETCH_NOTES";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_PEOPLE = "FETCH_PEOPLE";
export const FETCH_UNIT_MEMBERS = "FETCH_UNIT_MEMBERS";
export const FETCH_MEETINGS = "FETCH_MEETINGS";
export const FETCH_RECCURENCES = "FETCH_RECCURENCES";
export const FETCH_RECEIVERS = "FETCH_RECEIVERS";
export const FETCH_RECEIVERNOTES = "FETCH_RECEIVERNOTES";

export const SET_CURRENT_DECISION = "SET_CURRENT_DECISION";
export const SET_CURRENT_UNIT = "SET_CURRENT_UNIT";
export const UPDATE_DECISION = "UPDATE_DECISION";
export const UPDATE_MEETING = "UPDATE_MEETING";
export const UPDATE_MATTERS = "UPDATE_MATTERS";
export const UPDATE_RECCURENCES = "UPDATE_RECCURENCES";
export const UPDATE_RECEIVERS = "UPDATE_RECEIVERS";
export const UPDATE_PEOPLE = "UPDATE_PEOPLE";

/**
 * Action creators -> Describes what you want to do with store
 */

export const getDecisionData = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var decisionRef = db.ref("decisions");
    decisionRef.on("value", (snapshot) => {
      dispatch(fetchSuccess({ decisions: snapshot.val() }));
    });
  };
};

export const getInfoData = () => {
  return (dispatch) => {
    dispatch(fetchRequest());

    var infoRef = db.ref("information");
    infoRef.once("value").then((snapshot) => {
      dispatch(fetchSuccess({ information: snapshot.val() }));
    });
  };
};

export const getCategoryData = () => {
  return (dispatch) => {
    dispatch(fetchRequest());

    var categoryRef = db.ref("categories");
    categoryRef.once("value").then((snapshot) => {
      dispatch(fetchSuccess({ categories: snapshot.val() }));
    });
  };
};

export const getUnitData = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var unitRef = db.ref("units");
    unitRef.on("value", (snapshot) => {
      dispatch(fetchSuccess({ units: snapshot.val() }));
    });
  };
};

export const getPeople = () => {
  return (dispatch) => {
    dispatch(fetchRequest());

    var peopleRef = db.ref("people");
    peopleRef.on("value", (snapshot) => {
      dispatch(fetchPeople(snapshot.val()));
    });
  };
};

export const updatePerson = (personID, data) => {
  return (dispatch) => {
    var pRef = db.ref("people/" + personID);
    pRef.update(
      {
        firstName: data.firstname,
        lastName: data.lastname,
        gender: data.gender,
        title: data.title,
        education: data.education,
        phone: data.phone,
        email: data.email,
        office: data.office,
        website: data.website,
        lastModifiedDate: getDateString(),
      },
      (err) => {
        if (err) console.log(err);
        else dispatch(updatePeople({ personID: personID, data: data }));
      }
    );
  };
};

export const getUnitMembers = () => {
  return (dispatch) => {
    dispatch(fetchRequest());

    var memberRef = db.ref("unitMembers");
    memberRef.on("value", (snapshot) => {
      dispatch(fetchUnitMembers(snapshot.val()));
    });
  };
};

/* export const addMember = (data) => {
  return (dispatch) => {
    var memberRef = db.ref("unitMembers/" + data.id);

    var memberKey = memberRef.push().key;
    var updates = {};
    updates[memberKey] = data;
    memberRef.update(updates, (err) => {
      if (err) console.log(err);
    });
  };
}; */

export const addMember = (data) => {
  return (dispatch) => {
    var memberRef = db.ref("unitMembers/" + data.id);
    memberRef.update(
      {
        personID: data.personID,
        start: data.start,
        status: data.status,
        unitID: data.unitID,
        wokringHours: data.workingHours,
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const deleteMember = (id) => {
  return (dispatch) => {
    var decisionRef = db.ref("unitMembers/" + id);
    console.log("Deleting id: " + id);
    decisionRef.remove();
  };
};

/* export const addNewDecision = (data) => {
  return (dispatch) => {
    var decisionRef = db.ref("decisions/");
    console.log("Adding");
    decisionRef.push(
      {
        decisionTitle: data.issue,
        categoryID: data.category,
        matterID: data.matterID,
        keywords: data.keywords,
        referenceNr: data.refNr,
        decisionDate: data.decisionDate,
        upperDecisionID: data.upperDec,
        validFrom: data.validFrom,
        validTo: data.validTo,
        ownerUnit: data.unit,
        isPublic: data.isPublic,
        basis: data.basis,
        decisionText: data.decText,
        lastModifiedDate: getDateString(),
        // TODO, change to real users
        lastModifier: "Admin",
        direction: data.direction,
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
}; */

export const updateMemberHours = (member, hours) => {
  return (dispatch) => {
    var memberRef = db.ref("unitMembers/" + member.id);
    memberRef.update(
      {
        workingHours: hours,
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const getNotes = (id) => {
  return (dispatch) => {
    var notesRef = db.ref("notes/" + id);
    notesRef.on("value", (snapshot) => {
      dispatch(fetchNotes({ id: id, notes: snapshot.val() }));
    });
  };
};

export const setNotes = (id, note) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var notesRef = db.ref("notes/" + id);
    const noteKey = notesRef.push().key;
    var updates = {};
    updates[noteKey] = {
      note: note,
    };
    notesRef.update(updates, (err) => {
      if (err) console.log(err);
      else {
        notesRef.once("value").then((snapshot) => {
          dispatch(fetchNotes({ id: id, notes: snapshot.val() }));
        });
      }
    });
  };
};

export const removeNote = (decID, noteID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var notesRef = db.ref("notes/" + decID + "/" + noteID);
    notesRef.remove();
    notesRef.once("value").then((snapshot) => {
      dispatch(fetchNotes({ id: decID, notes: snapshot.val() }));
    });
  };
};

export const createMeeting = (unitID, meetingData) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var meetingRef = db.ref("meetings/" + unitID);
    const meetingKey = meetingRef.push().key;
    var updates = {};
    updates[meetingKey] = {
      title: meetingData.title,
      location: meetingData.location,
      dateTime: meetingData.dateTime,
      duration: meetingData.duration,
    };
    meetingRef.update(updates, (err) => {
      if (err) console.log(err);
    });
    dispatch(
      updateMeeting({
        unitID: unitID,
        meetingID: meetingKey,
        meetingData: meetingData,
      })
    );
  };
};

export const getAllMeetings = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var meetingRef = db.ref("meetings");
    meetingRef.on("value", (snapshot) => {
      dispatch(fetchMeetings({ meetings: snapshot.val() }));
    });
  };
};

export const getMeetings = (unitID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var meetingRef = db.ref("meetings/" + unitID);
    meetingRef.on("value", (snapshot) => {
      dispatch(fetchMeetings({ unit: unitID, meetings: snapshot.val() }));
    });
  };
};

export const removeMeeting = (unitID, meetingID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var meetingRef = db.ref("meetings/" + unitID + "/" + meetingID);
    meetingRef.remove();
    dispatch(
      updateMeeting({ unitID: unitID, meetingID: meetingID, remove: true })
    );
  };
};

export const getMatters = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var matterRef = db.ref("matters/");
    matterRef.on("value", (snapshot) => {
      dispatch(fetchSuccess({ matters: snapshot.val() }));
    });
  };
};

export const createMatter = (unitID, matterData) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var matterRef = db.ref("matters/" + unitID);
    const matterKey = matterRef.push().key;
    var updates = {};
    updates[matterKey] = {
      matter: matterData.matter,
      description: matterData.description,
      // TODO responsibleID: matterData.responsibleID,
      startDate: matterData.start,
      endDate: matterData.end,
      progress: matterData.progress,
    };
    matterRef.update(updates, (err) => {
      if (err) console.log(err);
    });
    dispatch(
      updateMatters({
        unitID: unitID,
        matterID: matterKey,
        matterData: matterData,
      })
    );
  };
};

export const removeMatter = (unitID, matterID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var matterRef = db.ref("matters/" + unitID + "/" + matterID);
    matterRef.remove();
    dispatch(
      updateMatters({ unitID: unitID, matterID: matterID, remove: true })
    );
  };
};

export const updateMatter = (unitID, matterData) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var matterRef = db.ref("matters/" + unitID + "/" + matterData.id);
    matterRef.update(
      {
        matter: matterData.matter,
        description: matterData.description,
        responsible: matterData.responsible,
        startDate: matterData.start,
        endDate: matterData.end,
        progress: matterData.progress,
      },
      (err) => {
        if (err) console.log(err);
        else
          dispatch(
            updateMatters({
              unitID: unitID,
              matterID: matterData.id,
              matterData: matterData,
            })
          );
      }
    );
  };
};

export const getAllReccurences = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var recRef = db.ref("reccurences");
    recRef.on("value", (snapshot) => {
      dispatch(fetchReccurences({ recs: snapshot.val() }));
    });
  };
};

export const getReccurences = (decID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var recRef = db.ref("reccurences/" + decID);
    recRef.on("value", (snapshot) => {
      dispatch(fetchReccurences({ dec: decID, recs: snapshot.val() }));
    });
  };
};

export const createReccurence = (decisionID, recData) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var recRef = db.ref("reccurences/" + decisionID);
    const recKey = recRef.push().key;
    var updates = {};
    updates[recKey] = {
      title: recData.title,
      date: recData.date,
      endDate: recData.endDate,
      status: recData.status,
      note: recData.note,
    };
    recRef.update(updates, (err) => {
      if (err) console.log(err);
    });
    dispatch(
      updateReccurences({ decID: decisionID, recID: recKey, recData: recData })
    );
  };
};

export const removeReccurence = (decisionID, recID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var recRef = db.ref("reccurences/" + decisionID + "/" + recID);
    recRef.remove();
    dispatch(
      updateReccurences({ decID: decisionID, recID: recID, remove: true })
    );
  };
};

export const saveReceivers = (decisionID, data) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var receiverRef = db.ref("receivers");
    var updates = {};
    updates[decisionID] = data;
    receiverRef.update(updates, (err) => {
      if (err) console.log(err);
    });
    dispatch(updateReceivers({ decID: decisionID, data: data }));
  };
};

export const getReceivers = (decisionID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    var receiverRef = db.ref("receivers/" + decisionID);
    receiverRef.on("value", (snapshot) => {
      dispatch(
        fetchReceivers({ decID: decisionID, receivers: snapshot.val() })
      );
    });
  };
};

export const updateReceiverNote = (decisionID, personID, note) => {
  return (dispatch) => {
    var receiverNotesRef = db.ref(
      "receiverNotes/" + decisionID + "/" + personID
    );
    receiverNotesRef.update(
      {
        note: note,
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const getReceiverNotes = (decisionID) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    const receiverNotesRef = db.ref("receiverNotes/" + decisionID);
    receiverNotesRef.on("value", (snapshot) => {
      dispatch(
        fetchReceiverNotes({ decID: decisionID, notes: snapshot.val() })
      );
    });
  };
};

export const savePersonAddress = (personID, address) => {
  return (dispatch) => {
    const pRef = db.ref("people/" + personID);
    pRef.update(
      {
        address: address,
        lastModifiedDate: getDateString(),
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

// export const getDocuments = (id) => {
//     return dispatch => {
//         var docRef = db.ref('documents/' + id)
//         docRef.on('value', (snapshot) => {
//             dispatch(fetchDocuments({id: id, files: snapshot.val()}))
//         })
//     }
// }

// export const setDocument = (id, file) => {
//     console.log(file, id)
//     return dispatch => {
//         dispatch(fetchRequest())
//         var docRef = db.ref('documents/' + id)
//         const docKey = docRef.push().key
//         var updates = {}
//         updates[docKey] = {
//             file: file
//         }
//         docRef.update(updates, (err) => {
//             if(err) console.log(err)
//         })
//     }
// }

export const deleteDecision = (id) => {
  return (dispatch) => {
    var decisionRef = db.ref("decisions/" + id);
    console.log("Deleting id: " + id);
    decisionRef.remove();
  };
};

export const addNewDecision = (data) => {
  return (dispatch) => {
    var decisionRef = db.ref("decisions/");
    console.log("Adding");
    decisionRef.push(
      {
        decisionTitle: data.issue,
        categoryID: data.category,
        matterID: data.matterID,
        keywords: data.keywords,
        referenceNr: data.refNr,
        decisionDate: data.decisionDate,
        upperDecisionID: data.upperDec,
        validFrom: data.validFrom,
        validTo: data.validTo,
        ownerUnit: data.unit,
        isPublic: data.isPublic,
        basis: data.basis,
        decisionText: data.decText,
        lastModifiedDate: getDateString(),
        // TODO, change to real users
        lastModifier: "Admin",
        direction: data.direction,
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const updateDecision = (data) => {
  return (dispatch) => {
    console.log("updateDecision");
    var decRef = db.ref("decisions/" + data.id);
    decRef.update(
      {
        decisionTitle: data.issue,
        categoryID: data.category,
        matterID: data.matterID,
        keywords: data.keywords,
        referenceNr: data.refNr,
        decisionDate: data.decisionDate,
        upperDecisionID: data.upperDec,
        validFrom: data.validFrom,
        validTo: data.validTo,
        ownerUnit: data.unit,
        isPublic: data.isPublic,
        basis: data.basis,
        decisionText: data.decText,
        lastModifiedDate: getDateString(),
        // TODO, change to real users
        lastModifier: "Admin",
        direction: data.direction,
      },
      (err) => {
        if (err) console.log(err);
        else {
          decRef.once("value").then((snapshot) => {
            dispatch(
              setUpdatedDecision({
                id: data.id,
                data: snapshot.val(),
              })
            );
          });
        }
      }
    );
  };
};

export const addNewUnit = (data) => {
  return (dispatch) => {
    var decisionRef = db.ref("units/");
    console.log("Adding");
    decisionRef.push(
      {
        nameEng: data.name,
        shortName: data.shortName,
        description: data.description,
        superUnitID: data.upperUnitID,
        startDate: data.startDate,
        endDate: data.endDate,
        type: data.type,
        status: data.status,
        // Todo real user
        lastModifier: "Admin",
        lastModifiedDate: getDateString(),
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const updateUnit = (data) => {
  return (dispatch) => {
    console.log("Updating unit");
    console.log(data);
    var unitRef = db.ref("units/" + data.id);
    unitRef.update(
      {
        nameEng: data.name,
        shortName: data.shortName,
        description: data.description,
        superUnitID: data.upperUnitID,
        startDate: data.startDate,
        endDate: data.endDate,
        type: data.type,
        status: data.status,
        // Todo real user
        lastModifier: "Admin",
        lastModifiedDate: getDateString(),
      },
      (err) => {
        if (err) console.log(err);
      }
    );
  };
};

export const deleteUnit = (id) => {
  return (dispatch) => {
    var unitRef = db.ref("units/" + id);
    console.log("Deleting id: " + id);
    unitRef.remove();
  };
};

export const getDateString = () => {
  // set up date in right format
  var today = new Date();
  var month = today.getMonth() + 1;
  var day = today.getDate();
  var hour = today.getHours();
  var minute = today.getMinutes();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  var datetime =
    today.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute;

  return datetime;
};

export const fetchRequest = () => {
  return { type: FETCH_REQUEST };
};

export const fetchSuccess = (fetchData) => {
  return { type: FETCH_SUCCESS, payload: fetchData };
};

export const fetchNotes = (data) => {
  return { type: FETCH_NOTES, payload: data };
};

export const fetchDocuments = (data) => {
  return { type: FETCH_DOCUMENTS, payload: data };
};

export const fetchPeople = (data) => {
  return { type: FETCH_PEOPLE, payload: data };
};

export const fetchUnitMembers = (data) => {
  return { type: FETCH_UNIT_MEMBERS, payload: data };
};

export const fetchMeetings = (data) => {
  return { type: FETCH_MEETINGS, payload: data };
};

export const fetchReccurences = (data) => {
  return { type: FETCH_RECCURENCES, payload: data };
};

export const fetchReceivers = (data) => {
  return { type: FETCH_RECEIVERS, payload: data };
};

export const fetchReceiverNotes = (data) => {
  return { type: FETCH_RECEIVERNOTES, payload: data };
};

export const setCurrentDecision = (id) => {
  return { type: SET_CURRENT_DECISION, payload: { decID: id } };
};

export const setCurrentUnit = (id) => {
  return { type: SET_CURRENT_UNIT, payload: { unitID: id } };
};

export const setUpdatedDecision = (decData) => {
  return {
    type: UPDATE_DECISION,
    payload: { id: decData.id, data: decData.data },
  };
};

export const updateMeeting = (data) => {
  return { type: UPDATE_MEETING, payload: data };
};

export const updateMatters = (data) => {
  return { type: UPDATE_MATTERS, payload: data };
};

export const updateReccurences = (data) => {
  return { type: UPDATE_RECCURENCES, payload: data };
};

export const updateReceivers = (data) => {
  return { type: UPDATE_RECEIVERS, payload: data };
};

export const updatePeople = (data) => {
  return { type: UPDATE_PEOPLE, payload: data };
};
