import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  Accordion,
  CardDeck,
  Badge,
} from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import guLogo from "../../img/guLogo.png";
import DecisionDetailModal from "../modals/DecisionDetailModal";

function HomeView({
  decisions,
  information,
  loading,
  showDecision,
  decisionUnits,
}) {
  const [infoIndex, setInfoIndex] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [modalDec, setModalDec] = React.useState(null);

  const displayInformation = () => {
    return (
      <Row id="information">
        <Col md={12}>
          <Row style={{ width: "100%" }}>
            <Col lg={10} md={8} sm={8}>
              <h5>I N F O R M A T I O N </h5>
            </Col>
            <Col id="infoArrowCol" lg={2} md={4} sm={4}>
              <BsArrowLeft
                id="arrowBtn"
                className="left"
                onClick={() => {
                  setInfoIndex(
                    infoIndex > 0 ? infoIndex - 1 : information.length - 1
                  );
                }}
              />
              <Badge pill variant="info" id="infoPill">
                {infoIndex + 1} / {information.length}
              </Badge>
              <BsArrowRight
                id="arrowBtn"
                onClick={() => {
                  setInfoIndex(
                    infoIndex < information.length - 1 ? infoIndex + 1 : 0
                  );
                }}
              />
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col md={12}>
              <p id="informationHeader">{information[infoIndex].title}</p>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col md={12}>
              <p id="informationContent">{information[infoIndex].infoText}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const displayDecisionUnit = (unit) => {
    return (
      <Row id="decisions" key={unit.header}>
        <Accordion id="decCardOuter" defaultActiveKey="Ongoing Decisions">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={unit.header}>
              <div id="mainPageAccordionHeader">
                {unit.header}{" "}
                <Badge pill variant="info" style={{ fontSize: "0.85rem" }}>
                  {unit.data.length}
                </Badge>{" "}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={unit.header}>
              <Card.Body id="outerCardBody">
                <CardDeck id="decCardDeck">
                  {/** TODO this is only set for ongoing decisions */}
                  {unit.data.length === 0
                    ? displayAlert()
                    : unit.data.map((d) => displayCard(d))}
                </CardDeck>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Row>
    );
  };

  const displayCard = (cardContent) => {
    return (
      <Col
        md={3}
        lg={3}
        style={{ padding: 0, maxWidth: "250px" }}
        key={cardContent.id}
      >
        <Card
          id="decCard"
          onClick={() => {
            setModalDec(cardContent);
            setShowModal(true);
          }}
        >
          <Card.Body style={{ padding: "10px" }}>
            <Card.Title style={{ fontSize: "0.9rem", height: "35px" }}>
              {cardContent.title.length >= 50
                ? cardContent.title.substring(0, 50) + "..."
                : cardContent.title}
            </Card.Title>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ fontSize: "0.8rem", height: "15px" }}
            >
              {" "}
              {cardContent.decisionDate}{" "}
            </Card.Subtitle>
            <Card.Text
              id="decInnerText"
              dangerouslySetInnerHTML={{ __html: cardContent.decisionText }}
            ></Card.Text>
            {/** Disclaimer: hard coded for testing purposes, logo should be dynamic */}
            <Card.Text style={{ fontSize: "0.85rem" }}>
              {" "}
              {cardContent.ownerUnit.id === "84" && (
                <img src={guLogo} width="25px" />
              )}{" "}
              {cardContent.ownerUnit.name}{" "}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const displayAlert = () => {
    // Display alert if no cards exist exists
    return (
      <Alert id="decAlert" variant="info">
        There is nothing to show here.
      </Alert>
    );
  };

  const decisionModal =
    loading || !decisions ? (
      <></>
    ) : (
      <DecisionDetailModal
        show={showModal}
        onHide={() => setShowModal(false)}
        current={modalDec}
        btnFunc={showDecision}
        home={true}
      />
    );

  return (
    <>
      {displayInformation()}
      <>{decisionUnits.map((unit) => displayDecisionUnit(unit))}</>
      {decisionModal}
    </>
  );
}

export default HomeView;
