import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoMdOpen } from 'react-icons/io'

function DecisionsSubunitView({current, decisions, view }) {

    const createDecisionRow = (dec) => {
        return (
            <Row id="tabContentTableRow" key={dec.id} >
                <Col md={4}>
                    {dec.title}
                </Col>
                <Col md={2}>
                    {dec.decisionDate}
                </Col>
                <Col md={{span:1, offset:5}}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'20px', height:'20px'}} onClick={() => view(dec.id)} />
                </Col>
            </Row>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={4}>
                        TITLE
                    </Col>
                    <Col md={2}>
                        DATE
                    </Col>
                    <Col md={{span:1, offset:5}}>
                        VIEW
                    </Col>
                </Row>
                <div id="tabContentTableRow" >
                    {decisions && decisions.filter(d => d.ownerUnit && d.ownerUnit.id === current.id).map(fd => createDecisionRow(fd))}
                </div>
            </Col>
        </Row>
    )
}

export default DecisionsSubunitView