import React from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import RichTextEditor from "react-rte";
import { WithContext as ReactTags } from "react-tag-input";
import WarningModal from "../modals/WarningModal";
import DirectionEditModal from "../modals/DirectionEditModal";
import DecisionTexteditorModal from "../modals/DecisionTexteditorModal";

function DecisionAddNewView({
  backBtn,
  handleSave,
  decisionList,
  categories,
  matters,
  units,
  createUpperDecString,
  nextId,
}) {
  // state for editor
  console.log(nextId);
  const [showEditor, setShowEditor] = React.useState(false);
  const [modalRichValue, setModalRichValue] = React.useState(
    RichTextEditor.createEmptyValue()
  );
  // 'basis'/'decText'
  const [modalMode, setModalMode] = React.useState("decText");

  const warnings = {
    reset: {
      message: "Do you want to reset your changes?",
      btn1: "Cancel",
      btn2: "Reset",
    },
    leaveNoSave: {
      message: "Do you want to leave without saving?",
      btn1: "Save my changes",
      btn2: "Discard changes",
    },
  };
  const [showResetWarning, setShowResetWarning] = React.useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = React.useState(false);
  const [showDirectionEdit, setShowDirectionEdit] = React.useState(false);

  // keep track of unsaved changes
  const [changed, setChanged] = React.useState(false);

  // Form values
  const [issue, setIssue] = React.useState("");
  const [category, setCategory] = React.useState({
    name: "No category",
    id: null,
  });
  const [keywords, setKeywords] = React.useState([]);
  const [matter, setMatter] = React.useState({ id: null, name: "No matter" });
  const [refNr, setRefNr] = React.useState("No reference number");
  const [upperDec, setUpperDec] = React.useState("null");
  const [decisionDate, setDecisionDate] = React.useState("2000-01-01");
  const [validFrom, setValidFrom] = React.useState("2000-01-01");
  const [validTo, setValidTo] = React.useState("2000-01-01");
  const [unit, setUnit] = React.useState({ name: "No category", id: null });
  const [isPublic, setIsPublic] = React.useState(false);
  const [basis, setBasis] = React.useState("No basis");
  const [decText, setDecText] = React.useState("No decision");
  const [direction, setDirection] = React.useState(null);

  const htmlRegex = /<[a-z/]*>/g;

  let unitMatters = matters[unit.id];
  console.log(direction);
  const handleChange = (event, setFunc) => {
    if (!changed) setChanged(true);
    console.log(direction);
    setFunc(event);
  };

  const handleEditorModalShow = (mode) => {
    setModalMode(mode);
    const editorString = mode === "decText" ? decText : basis;
    setModalRichValue(
      RichTextEditor.createValueFromString(editorString, "html")
    );
    setShowEditor(true);
  };

  const handleLeaveBtn = () => {
    if (!changed) {
      backBtn();
    } else {
      setShowLeaveWarning(true);
    }
  };

  const resetFunc = () => {
    setIssue("");
    setCategory({ name: "No category", id: null });
    setKeywords([]);
    setMatter({ id: null, name: "No matter" });
    setRefNr("No reference number");
    setUpperDec("null");
    setDecisionDate("2000-01-01");
    setValidFrom("2000-01-01");
    setValidTo("2000-01-01");
    setUnit({ name: "No category", id: null });
    setIsPublic(false);
    setBasis("No basis");
    setDecText("No decision");
    setDirection(null);

    setChanged(false);
  };

  const getNextId = () => {
    const ids = decisionList.map((user) => user.id);
    const sorted = ids.sort((a, b) => a - b);
    const maxId = parseInt(sorted[sorted.length - 1]);
    return maxId + 1;
  };

  //Compiles new decisiondata and push to database.
  const saveFunc = (e) => {
    //e.preventDefault()
    setChanged(false);

    const data = {
      //Picks the highest decisionList.id and adds one
      //Might not be the best way to generate a new id but
      //it will work for now
      //id: Math.max(...decisionList.map(user => user.id))+1,
      //Cant add Keywords
      id: getNextId(),
      issue: issue,
      category: category.id,
      keywords: keywords,
      matterID: matter.id,
      refNr: refNr,
      upperDec: upperDec,
      decisionDate: decisionDate,
      validFrom: validFrom,
      validTo: validTo,
      unit: unit.id,
      isPublic: isPublic,
      basis: basis,
      decText: decText,
      direction: direction,
    };
    handleSave(data);
  };

  const handleKeywordAddition = (tag) => {
    setChanged(true);
    setKeywords([...keywords, tag]);
  };

  const handleKeywordDelete = (index) => {
    setChanged(true);
    setKeywords(keywords.filter((kw, i) => i !== index));
  };
  //todo delete reaccurances in add?
  //Hur fungerar matter, kopplat till units?
  return (
    <React.Fragment>
      <div
        style={{
          borderBottom: "1px solid var(--sidebar-gray)",
          paddingBottom: "1rem",
          paddingTop: "0.5rem",
        }}
      >
        <Row style={{ paddingTop: "1%" }}>
          <Col xl={9} lg={8} style={{ paddingLeft: "2rem" }}>
            Add new: {issue}
          </Col>
          <Col xl={3} lg={4} style={{ textAlign: "center" }}>
            <Button
              id="backbtn"
              variant="outline-secondary"
              size="sm"
              onClick={() => handleLeaveBtn()}
            >
              Back
            </Button>
            <Button
              id="resetbtn"
              size="sm"
              disabled={!changed}
              onClick={() => setShowResetWarning(true)}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <Row id="decisionForm">
        <Form style={{ width: "100%", padding: "2rem" }}>
          <Row>
            <Col md={6}>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Issue
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    id="formIssue"
                    size="sm"
                    type="text"
                    value={issue}
                    onChange={(e) => {
                      handleChange(e.target.value, setIssue);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? document.getElementById("formIssue").blur()
                        : NaN
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Category
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    value={category.name}
                    onChange={(e) => {
                      handleChange(
                        {
                          name: e.target.value,
                          id: e.target.options[
                            e.target.options.selectedIndex
                          ].getAttribute("data-key"),
                        },
                        setCategory
                      );
                    }}
                  >
                    <option>Choose one</option>
                    {categories.map((elem) => (
                      <option key={elem.id} data-key={elem.id}>
                        {elem.category}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Keywords
                </Form.Label>
                <Col sm={10}>
                  <ReactTags
                    tags={keywords}
                    handleDelete={handleKeywordDelete}
                    handleAddition={handleKeywordAddition}
                    placeholder="Add keyword"
                    autofocus={false}
                    inputFieldPosition="inline"
                    allowDragDrop={false}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Matter
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    value={matter.name}
                    onChange={(e) => {
                      handleChange(
                        {
                          name: e.target.value,
                          id: e.target.options[
                            e.target.options.selectedIndex
                          ].getAttribute("data-key"),
                        },
                        setMatter
                      );
                    }}
                  >
                    <option data-key={null}>None</option>
                    {unitMatters && unitMatters.length !== 0 ? (
                      Object.keys(unitMatters).map((key) => (
                        <option key={key} data-key={key}>
                          {unitMatters[key].matter}
                        </option>
                      ))
                    ) : (
                      <option disabled>None to choose</option>
                    )}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Reference Nr
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={refNr}
                    onChange={(e) => {
                      handleChange(e.target.value, setRefNr);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Upper Decision
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    value={createUpperDecString(upperDec)}
                    onChange={(e) => {
                      handleChange(
                        e.target.options[
                          e.target.options.selectedIndex
                        ].getAttribute("data-key"),
                        setUpperDec
                      );
                    }}
                  >
                    <option>Choose one</option>
                    {decisionList.map((dec) => {
                      return (
                        <option key={dec.id} data-key={dec.id}>
                          {dec.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Decision Date
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="date"
                    value={decisionDate !== null ? decisionDate : ""}
                    onChange={(e) => {
                      handleChange(e.target.value, setDecisionDate);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Valid From
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="date"
                    value={validFrom !== null ? validFrom : ""}
                    onChange={(e) => {
                      handleChange(e.target.value, setValidFrom);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Valid To
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="date"
                    value={validTo !== null ? validTo : ""}
                    onChange={(e) => {
                      handleChange(e.target.value, setValidTo);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Next Reccurence
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={"getNextRec()"}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Unit
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="select"
                    value={unit.name}
                    onChange={(e) => {
                      handleChange(
                        {
                          name: e.target.value,
                          id: e.target.options[
                            e.target.options.selectedIndex
                          ].getAttribute("data-key"),
                        },
                        setUnit
                      );
                    }}
                  >
                    <option>Choose one</option>
                    {units.map((elem) => (
                      <option key={elem.id} data-key={elem.id}>
                        {elem.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Basis
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    style={{ cursor: "pointer" }}
                    value={basis.replaceAll(htmlRegex, "")}
                    readOnly
                    onClick={() => {
                      handleEditorModalShow("basis");
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Decision
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    style={{ cursor: "pointer" }}
                    value={decText.replaceAll(htmlRegex, "")}
                    readOnly
                    onClick={() => {
                      handleEditorModalShow("decText");
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Direction
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    size="sm"
                    type="text"
                    style={{ cursor: "pointer" }}
                    value={direction ? direction.name : ""}
                    readOnly
                    onClick={() => setShowDirectionEdit(true)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                {/** TODO */}
                <Form.Label id="formLabel" column sm={2}>
                  Perform
                </Form.Label>
                <Col sm={10}>
                  <Form.Control size="sm" type="text" value={""} readOnly />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label id="formLabel" column sm={2}>
                  Public
                </Form.Label>
                <Col sm={10}>
                  <Form.Check
                    type="checkbox"
                    value={isPublic}
                    onChange={() => {
                      handleChange(!isPublic, setIsPublic);
                    }}
                  />
                </Col>
              </Form.Group>
              <Button
                id="submitBtn"
                variant="secondary"
                type="submit"
                disabled={!changed}
                onClick={(e) => {
                  saveFunc(e);
                }}
              ></Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <WarningModal
        show={showResetWarning}
        onHide={() => setShowResetWarning(false)}
        message={warnings.reset.message}
        btn1Msg={warnings.reset.btn1}
        btn1Func={() => setShowResetWarning(false)}
        btn2Msg={warnings.reset.btn2}
        btn2Func={() => {
          resetFunc();
          setShowResetWarning(false);
        }}
      />
      <WarningModal
        show={showLeaveWarning}
        onHide={() => setShowLeaveWarning(false)}
        message={warnings.leaveNoSave.message}
        btn1Msg={warnings.leaveNoSave.btn1}
        btn1Func={() => {
          saveFunc();
          setShowLeaveWarning(false);
        }}
        btn2Msg={warnings.leaveNoSave.btn2}
        btn2Func={() => {
          backBtn();
          setShowLeaveWarning(false);
        }}
      />
      <DirectionEditModal
        direction={direction}
        currentDec={issue}
        show={showDirectionEdit}
        onHide={(data) => {
          setDirection(data);
          setShowDirectionEdit(false);
          if (!changed) setChanged(true);
        }}
      />
      <DecisionTexteditorModal
        show={showEditor}
        onHide={() => setShowEditor(false)}
        modalMode={modalMode}
        setChanged={setChanged}
        setDecText={setDecText}
        setBasis={setBasis}
        modalRichValue={modalRichValue}
        setModalRichValue={setModalRichValue}
      />
    </React.Fragment>
  );
}

export default DecisionAddNewView;
