import React from 'react'
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { GrAddCircle } from 'react-icons/gr'
import { ImCross } from 'react-icons/im'
import { FiEdit } from 'react-icons/fi'
import MatterModal from '../../modals/MatterModal'
import MatterEditModal from '../../modals/MatterEditModal'

function MatterSubunit({matters, create, removeMatter, people, members, update }) {

    const [show, setShow] = React.useState(false)
    const [showEdit, setShowEdit] = React.useState(false)
    const [editID, setEditID] = React.useState(null)

    const createMatterRow = (key) => {
        const person = people.filter(p => p.id === matters[key].responsible)[0]
        const res = person ? person.firstname + ' ' + person.lastname : 'None'
        return (
            <Row id="tabContentTableRow" key={key} >
                <Col md={3}>
                    {matters[key].matter}
                </Col>
                <Col md={3}>
                    {matters[key].description}
                </Col>
                <Col md={2}>
                    {res}
                </Col>
                <Col md={1}>
                    {matters[key].startDate}
                </Col>
                <Col md={1}>
                    {matters[key].endDate}
                </Col>
                <Col md={1}>
                    {matters[key].progress}
                </Col>
                <Col md={1} style={{paddingLeft:'30px'}} >
                    <FiEdit id="subunitAddBtn" style={{width:'18px', height:'18px', marginRight:'20px'}} onClick={() => {setEditID(key); setShowEdit(true)}} />
                    <ImCross id="subunitAddBtn" onClick={() => removeMatter(key)} />
                    
                </Col>
            </Row>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row style={{margin:'10px 0px 0px 0px', backgroundColor:'var(--sidebar-gray)', height:'33px', padding:'10px 2px'}} >
                    <Col md={3}>
                        MATTER
                    </Col>
                    <Col md={3}>
                        DESCRIPTION
                    </Col>
                    <Col md={2}>
                        RESPONSIBLE
                    </Col>
                    <Col md={1}>
                        START
                    </Col>
                    <Col md={1}>
                        END
                    </Col>
                    <Col md={1}>
                        PROGRESS
                    </Col>
                    <Col md={1} style={{paddingLeft:'30px'}} >
                        <OverlayTrigger placement="left" overlay={<Tooltip style={{fontSize:'0.7rem'}} >Add new</Tooltip>} > 
                            <GrAddCircle id="subunitAddBtn" style={{height:'18px', width:'18px'}} onClick={() => {setEditID(null); setShow(true)}} />
                        </OverlayTrigger>
                    </Col>
                </Row>
                {matters && Object.keys(matters).map(key => createMatterRow(key))}
            </Col>
            <MatterModal show={show} onHide={() => setShow(false)} saveFunc={(data) => create(data)} members={members} people={people} />
            <MatterEditModal show={showEdit} onHide={() => setShowEdit(false)} saveFunc={(data) => update(data)} members={members} people={people} matters={matters} editID={editID} />
        </Row>
    )
}

export default MatterSubunit