import React from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import logo from "../../img/logoNoBackground.png";
import { getActionTitle } from "../../functions/directionActionTitle";

const DecisionDetailModal = ({
  show,
  onHide,
  current,
  btnFunc,
  home,
  receivers,
  people,
}) => {
  const [homeState, setHomeState] = React.useState(home);

  const validity = current
    ? !current.validFrom && !current.validTo
      ? "No specific period"
      : !current.validFrom
      ? "- " + current.validTo
      : !current.validTo
      ? current.validFrom + " -"
      : current.validFrom + " - " + current.validTo
    : "";

  const getReceiverName = (id) => {
    const person = people.filter((p) => p.id === id)[0];

    return <p key={id}>{person.firstname + " " + person.lastname}</p>;
  };

  // Only returns Modal once first decision is clicked and current exists
  return current ? (
    <Modal
      show={show}
      onHide={() => {
        setHomeState(home);
        onHide();
      }}
      size="lg"
    >
      <div id="printSection">
        <Modal.Header>
          <Modal.Title style={{ width: "100%" }}>
            <Row>
              <Col md={2}>
                <img src={logo} alt="BasedOn" width="60" />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
            <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
              <b>U N I T</b>
            </Col>
            <Col md={10}>{current.ownerUnit.name}</Col>
          </Row>
          <div style={{ borderTop: "1px solid var(--sidebar-gray)" }}>
            <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
              <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                <b>I S S U E</b>
              </Col>
              <Col md={10}>{current.title}</Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
              <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                <b>D E C I S I O N</b>
              </Col>
              <Col md={10} id="decisionText">
                <div
                  dangerouslySetInnerHTML={{ __html: current.decisionText }}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
              <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                <b>B A S I S</b>
              </Col>
              <Col md={10} id="decisionText">
                <div
                  dangerouslySetInnerHTML={{ __html: current.decisionText }}
                />
              </Col>
            </Row>
          </div>
          {homeState ? (
            <></>
          ) : (
            <>
              <div style={{ borderTop: "1px solid var(--sidebar-gray)" }}>
                <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                  <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                    <b>
                      D E C I S I O N <br /> D A T E
                    </b>
                  </Col>
                  <Col me={10}>
                    {current.decisionDate === null
                      ? "Not set"
                      : current.decisionDate}
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "5px" }}>
                  <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                    <b>V A L I D</b>
                  </Col>
                  <Col md={10}>{validity}</Col>
                </Row>
              </div>
              <div style={{ borderTop: "1px solid var(--sidebar-gray)" }}>
                <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                  <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                    <b>D I R E C T I O N</b>
                  </Col>
                  <Col me={10}>
                    {current.direction
                      ? current.direction.name
                      : "No direction"}
                  </Col>
                </Row>
                {current.direction && (
                  <>
                    <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                      <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                        <b>D A T E</b>
                      </Col>
                      <Col me={10}>{current.direction.date}</Col>
                    </Row>
                    <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                      <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                        <b>S T A G E S</b>
                      </Col>
                      <Col me={10}>
                        {current.direction.stages.map((s, i) => (
                          <Row
                            style={{
                              width: "100%",
                              borderBottom: "1px dotted var(--sidebar-gray)",
                            }}
                          >
                            <Col md={4}>{s.name}</Col>
                            <Col md={4}>{s.description}</Col>
                            <Col md={4}>
                              {getActionTitle(current.direction.stages, i)}
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <div style={{ borderTop: "1px solid var(--sidebar-gray)" }}>
                <Row style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                  <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                    <b>R E C I E V E R S</b>
                  </Col>
                  <Col me={10}>
                    {receivers && receivers.length !== 0
                      ? receivers.map((r) => getReceiverName(r))
                      : "No receivers"}
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "5px" }}>
                  <Col md={2} style={{ color: "gray", fontSize: "12px" }}>
                    <b>T A S K S</b>
                  </Col>
                  {/** TODO */}
                  <Col me={10}>No tasks</Col>
                </Row>
              </div>
            </>
          )}
        </Modal.Body>
      </div>
      <Modal.Footer>
        {homeState ? (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip style={{ fontSize: "12px" }}>Extract</Tooltip>}
          >
            <FaFileDownload
              id="homeDecDetailsIcons"
              onClick={() => setHomeState(false)}
            />
          </OverlayTrigger>
        ) : (
          <> </>
        )}
        <Button
          id="btn"
          variant="outline-secondary"
          onClick={() => {
            setHomeState(home);
            onHide();
          }}
        >
          Close
        </Button>
        <Button
          id="btn"
          variant="outline-secondary"
          onClick={() => {
            if (homeState) {
              btnFunc(current.id);
            } else {
              window.print();
            }
          }}
        >
          {homeState ? "Details" : "Print"}
        </Button>
        {/** TODO add edit btn to get straight to edit */}
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export default DecisionDetailModal;
