import React from 'react'
import { Col, Row, Modal } from 'react-bootstrap'
import { BsThreeDots } from 'react-icons/bs'
import { IoMdOpen } from 'react-icons/io'

function DecisionsByMatter({matters, decisions, viewDecision }) {

    const [chosen, setChosen] = React.useState([])
    const [show, setShow] = React.useState(false)
    const [filteredDec, setFilteredDec] = React.useState([])
    const htmlRegex = /<[a-z/]*>/g

    const getFilterString = () => {
        if(chosen.length === 0 || chosen.length === getNumberOfMatters()) return <p id="categoryHeader" >All</p>
        else return chosen.map(c => <p key={c} id="categoryHeader"> {getMatterName(c)}</p>)
    }

    const getNumberOfMatters = () => {
        var i = 0
        Object.keys(matters).forEach(key => {
            i += Object.keys(matters[key]).length
        })

        return i
    }

    const getMatterName = (id) => {
        var tmp = ''
        Object.keys(matters).forEach(key => {
            Object.keys(matters[key]).forEach(m => {
                if(m === id) tmp = matters[key][m].matter
            })
        })
        return tmp
    }

    const changeFilter = (id) => {
        const elem = document.getElementById('mf' + id)
        elem.classList.toggle('chosen')
        if(chosen.indexOf(id) === -1) {
            setChosen([...chosen, id])
            setFilteredDec(decisions.filter(d => (chosen.indexOf(d.matter.id) !== -1 || d.matter.id === id)))
        }
        else {
            setChosen(chosen.filter(c => c !== id))
            setFilteredDec(decisions.filter(d => (chosen.indexOf(d.matter.id) !== -1 && d.matter.id !== id)))
        }
    }

    const chooseAll = () => {
        const arrTmp = []
        Object.keys(matters).forEach(key => {
            Object.keys(matters[key]).forEach(m => {
                document.getElementById('mf' + m).classList.add('chosen')
                arrTmp.push(m)
            })
        })
        setChosen(arrTmp)
        setFilteredDec(decisions)
    }

    const chooseNone = () => {
        chosen.forEach(c => {
            document.getElementById('mf' + c).classList.remove('chosen')
        })
        setChosen([])
        setFilteredDec(decisions)
    } 

    const createDecisionRow = (dec) => {
        return (
            <Row key={dec.id} id="tabContentTableRow" >
                <Col md={4}>
                    {(dec.title.length >= 100) ? dec.title.substring(0,100) + '...' : dec.title}
                </Col>
                <Col md={5}>
                    <div dangerouslySetInnerHTML={{__html:(dec.decisionText.length > 100 ? dec.decisionText.replaceAll(htmlRegex,'').substring(0,100)+'...' : dec.decisionText.replaceAll(htmlRegex,''))}}/>
                </Col>
                <Col md={2}>
                    {dec.matter.name}
                </Col>
                <Col md={1}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'18px', height:'18px'}} onClick={() => viewDecision(dec.id)} />
                </Col>
            </Row>
        )
    }
    return (
        <>
        <Row>
            <Col md={12}>
                <p style={{fontSize:'1.3rem'}} >Choose matters <BsThreeDots id="addFilterBtn" onClick={() => setShow(true)}/> </p>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                {getFilterString()}
            </Col>
        </Row>
        <Row id="tabContentHeader" >
            <Col md={4}>
                ISSUE
            </Col>
            <Col md={5}>
                DECISION
            </Col>
            <Col md={2}>
                MATTER
            </Col>
            <Col md={1}>
                VIEW
            </Col>
        </Row>
        <div>
            {(chosen.length === 0 || chosen.length === getNumberOfMatters()) 
                ? decisions.map(cd => createDecisionRow(cd)) 
                : filteredDec.map(cd => createDecisionRow(cd))}
        </div>
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Body>
                Choose what matters to show
                <div  >
                    {matters && Object.keys(matters).map(key => {
                        return (
                            Object.keys(matters[key]).map(m => {
                                return (
                                    <p key={m} 
                                        id={"mf" + m}
                                        className={'categoryFilter' + ((chosen.indexOf(m) === -1) ? '' : ' chosen')} 
                                        onClick={() => changeFilter(m)} >
                                        {matters[key][m].matter}
                                    </p>
                                )
                            })
                        )
                    }) }
                    <br/><br/>
                    <p id="cfAll" className='categoryFilter' onClick={() => chooseAll()} >All</p>
                    <p id="cfNone" className='categoryFilter' onClick={() => chooseNone()} >None</p>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )

}

export default DecisionsByMatter