import firebase from "firebase/app"
import "firebase/database";

firebase.initializeApp({
    apiKey: "AIzaSyAqSOLVxUnKl7geByIlJH-xvy2vkuxjz-M",
    authDomain: "basedon-d2b3e.firebaseapp.com",
    databaseURL: "https://basedon-d2b3e-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "basedon-d2b3e",
    storageBucket: "basedon-d2b3e.appspot.com",
    messagingSenderId: "26283809278",
    appId: "1:26283809278:web:99aec4d07d7507a993c8af"
})

export const db = firebase.database()

