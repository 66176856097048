import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { GrAddCircle } from 'react-icons/gr'
import { ImCross } from 'react-icons/im'
import DecisionAddRecurrencesModal from '../../modals/DecisionAddRecurrencesModal'

function RecurrencesSubunitView({edit, reccurences, create, remove}) {

    const [showAdd, setShowAdd] = React.useState(false)

    const createRecurrencesRow = (key) => {
        var date = reccurences[key].date
        if(reccurences[key].endDate) date += ' - ' + reccurences[key].endDate
        return (
            <Row id="tabContentTableRow" key={key} >
                <Col md={3}>
                    {reccurences[key].title}
                </Col>
                <Col md={2}>
                    {date}
                </Col>
                <Col md={1}>
                    {reccurences[key].status}
                </Col>
                <Col md={5}>
                    {reccurences[key].note}
                </Col>
                {edit ? (
                    <Col md={1} style={{paddingLeft:'30px'}} >
                        <ImCross id="subunitAddBtn" onClick={() => remove(key)} />
                    </Col>
                ) :''}
            </Row>
        )
    }

    return (
    <Row style={{margin:0}} >
        <Col md={12}>
            <Row id="tabContentHeader" >
                <Col md={3}>
                    TITLE
                </Col>
                <Col md={2}>
                    DATE
                </Col>
                <Col md={1}>
                    STATUS
                </Col>
                <Col md={5}>
                    NOTE
                </Col>
                {edit ? (
                    <Col md={1} style={{paddingLeft:'30px'}} >
                        <GrAddCircle id="subunitAddBtn" style={{height:'18px', width:'18px'}} onClick={() => setShowAdd(true)} />
                    </Col>
                ) : ''}
            </Row>
            <div id="tabContentTable">
                {reccurences && Object.keys(reccurences).map(key => createRecurrencesRow(key))}
            </div>
        </Col>
        <DecisionAddRecurrencesModal show={showAdd} onHide={() => setShowAdd(false)} saveFunc={(data) => create(data)} />
    </Row>
    )
}

export default RecurrencesSubunitView