import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Timeline, Events, TextEvent, Button, themes, createTheme } from '@merc/react-timeline'


function TraceTimeline({decisions, units, reccurences, meetings, viewDecision }) {

    const [upcoming, setUpcoming] = React.useState(true)

    const getTimelineItems = () => {
        var arr = []
        decisions.forEach(d => {
            if(d.validTo) arr.push({
                id: d.id,
                issue: d.title,
                decision: d.decisionText,
                date: d.validTo,
                owner: d.ownerUnit.name,
                type: 'Deadline',
            })
        })

        Object.keys(reccurences).forEach(rKey => {
            Object.keys(reccurences[rKey]).forEach(r => {
                const dec = decisions.filter(d => rKey === d.id)[0]
                if(dec) arr.push({
                    id: dec.id,
                    title: reccurences[rKey][r].title ? reccurences[rKey][r].title : 'No title',
                    issue: dec.title,
                    decision: dec.decisionText,
                    owner: dec.ownerUnit.name,
                    date: reccurences[rKey][r].date,
                    endDate: reccurences[rKey][r].endDate,
                    type: 'Reccurence'
                })
            })
        })

        Object.keys(meetings).forEach(mKey => {
            Object.keys(meetings[mKey]).forEach(m => {
                const dateTimeArr = meetings[mKey][m].dateTime.split(' ')
                arr.push({
                    id: m,
                    unit: units.filter(u => u.id === mKey)[0].name,
                    location: meetings[mKey][m].location ? meetings[mKey][m].location : 'Unknown',
                    date: dateTimeArr[0] ? dateTimeArr[0] : null,
                    time: dateTimeArr[1] ? dateTimeArr[1] : null,
                    duration: meetings[mKey][m].duration,
                    type: 'Meeting'
                })
            })
        })

        arr.sort((a,b) => { return new Date(a.date) - new Date(b.date) })

        return arr
    }

    const items = upcoming 
        ? getTimelineItems().filter(e => new Date(e.date) > new Date())
        : getTimelineItems()

    const theme = createTheme(themes.default, {
            card: {
                backgroundColor: 'var(--sidebar-gray)',
            },
            date: {
                backgroundColor: 'var(--info-dark)',
            },
            marker: {
                borderColor: 'var(--info-dark)',
            },
            timelineTrack: {
                backgroundColor: 'black'
            },
            button: {
                backgroundColor: 'var(--info)'
            }
        })

    return (
        <>
        <Row>
            <Col md={12} >
                <div style={{borderBottom:'1px solid var(--sidebar-gray)', marginBottom:'20px'}} >
                    <p className={'categoryFilter' + (upcoming ? ' chosen' : '')} onClick={() => setUpcoming(true)} >Show upcoming</p>
                    <p className={'categoryFilter' + (upcoming ? '' : ' chosen')} onClick={() => setUpcoming(false)} >Show all</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={{offset:1, span:10}} style={{height:'700px', overflowY:'auto'}} >
                <Timeline theme={theme} opts={{layout: 'alt-evts-inline-date'}} >
                    <Events>
                        {items.map(i => 
                            i.type === 'Meeting'
                                ? (
                                    <TextEvent 
                                        key={i.time}
                                        date={i.date} 
                                        text={`**Meeting** <br/><br/> Unit — ${i.unit} <br/> Location — ${i.location} <br/> Time — ${i.time}, ${i.duration}`} 
                                    />
                                ) : i.type === 'Deadline'
                                    ? (
                                        <TextEvent
                                            key={i.date} 
                                            date={i.date} 
                                            text={`**${i.type}** <br/><br/> Issue — ${i.issue} <br/> Unit — ${i.owner} `} 
                                        >
                                            <div>
                                                <Button onClick={() => viewDecision(i.id)}>VIEW</Button>
                                            </div>
                                        </TextEvent>
                                    )
                                    : (
                                        <TextEvent 
                                            key={i.date}
                                            date={i.date} 
                                            text={`**${i.type}** <br/><br/> Title — ${i.title} <br/> Issue — ${i.issue} <br/> Unit — ${i.owner} `} 
                                        >
                                            <div>
                                                <Button onClick={() => viewDecision(i.id)}>VIEW</Button>
                                            </div>
                                        </TextEvent>
                                    )
                            
                        )}
                    </Events>
                </Timeline>
            </Col>
        </Row>

        </>
    )
}

export default TraceTimeline