export const getDateString = () => {
    // set up date in right format
    var today = new Date()
    var month = today.getMonth()+1
    var day = today.getDate()
    var hour = today.getHours()
    var minute = today.getMinutes()
    if (month < 10){month = '0'+month} 
    if (day < 10){day = '0'+day}
    if (hour < 10){hour = '0'+hour}
    if (minute < 10){minute = '0'+minute}
    var datetime = today.getFullYear()+'-'+month+'-'+day + " " + hour + ":" + minute

    return datetime
}