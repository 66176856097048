import React from 'react'
import { Col, Row } from 'react-bootstrap'
import comingsoon from '../../img/comingsoon.png'

function TaskContainer() {
    return (
        <Col id="mainviewcol" >
        <>
        <Row style={{height:'3rem', backgroundColor:'lightgrey'}}>
            <Col md={2} style={{fontSize:'1.8rem'}} >TASKS</Col>
        </Row>
        <Col md={{offset:4, span:6}}>
            <img src={comingsoon} alt="coming soon"/>
        </Col>
        </>
        
    </Col>
    )
}

export default TaskContainer