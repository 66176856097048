import React from "react";
import { Col, Row, Tabs, Tab } from "react-bootstrap";
import comingsoon from "../../img/comingsoon.png";
import MembersSubunitView from "../subunits/unit/MembersSubunitView";
import DecisionsSubunitView from "../subunits/unit/DecisionsSubunitView";
import MatterModal from "../modals/MatterModal";
import MeetingSubunitView from "../subunits/unit/MeetingSubUnitView";
import MatterSubunit from "../subunits/unit/MatterSubunit";

function UnitTabsView({
  current,
  members,
  people,
  updateMemberHours,
  decisions,
  viewDecision,
  createMeeting,
  meetings,
  removeMeeting,
  createMatter,
  matters,
  removeMatter,
  updateMatter,
  addMember,
  deleteMember,
}) {
  const [showMatter, setShowMatter] = React.useState(false);

  const rmMeetingFunc = (id) => {
    removeMeeting(current.id, id);
  };

  const rmMatterFunc = (id) => {
    removeMatter(current.id, id);
  };

  const unitMembers = members.filter((m) => m.unitID === current.id);

  return (
    <Row>
      <Col md={12}>
        <Tabs fill defaultActiveKey="members" style={{ width: "100%" }}>
          <Tab eventKey="members" title="MEMBERS" id="tabTitle">
            <MembersSubunitView
              current={current}
              members={members}
              people={people}
              updateMemberHours={updateMemberHours}
              addMember={addMember}
              deleteMember={deleteMember}
            />
          </Tab>
          <Tab eventKey="staff" title="STAFF" id="tabTitle">
            <Col md={{ offset: 4, span: 6 }}>
              <img src={comingsoon} alt="coming soon" />
            </Col>
          </Tab>
          <Tab eventKey="documents" title="DOCUMENTS" id="tabTitle">
            <Col md={{ offset: 4, span: 6 }}>
              <img src={comingsoon} alt="coming soon" />
            </Col>
          </Tab>
          <Tab eventKey="decisions" title="DECISIONS" id="tabTitle">
            <DecisionsSubunitView
              current={current}
              decisions={decisions}
              view={viewDecision}
            />
          </Tab>
          <Tab eventKey="meetings" title="MEETINGS" id="tabTitle">
            <MeetingSubunitView
              meetings={meetings[current.id]}
              createMeeting={createMeeting}
              removeMeeting={rmMeetingFunc}
            />
          </Tab>
          <Tab eventKey="keywords" title="KEYWORDS" id="tabTitle">
            <Col md={{ offset: 4, span: 6 }}>
              <img src={comingsoon} alt="coming soon" />
            </Col>
          </Tab>
          <Tab eventKey="matters" title="MATTERS" id="tabTitle">
            <MatterSubunit
              create={createMatter}
              matters={matters[current.id]}
              removeMatter={rmMatterFunc}
              people={people}
              members={unitMembers}
              update={updateMatter}
            />
          </Tab>
        </Tabs>
      </Col>
      <MatterModal
        show={showMatter}
        onHide={() => setShowMatter(false)}
        saveFunc={() => console.log("save")}
      />
    </Row>
  );
}

export default UnitTabsView;
