import React from "react";
import { Col, Row } from "react-bootstrap";
import RichTextEditor from "react-rte";
import { formatStringExtract } from "../../functions/filterData";

const htmlRegex = /<[a-z/]*>/g;
function DecisionListContainer({ decisions, setCurrent }) {
  return (
    /** TODO list a (signed in) users decisions, for now it's all. */
    <Col md={12} style={{ marginTop: "20px" }}>
      {decisions.map((d) => (
        <Row>
          <div
            id="decListItems"
            className="w-100 pl-3 pr-3 pt-2 pb-0"
            key={d.id}
            onClick={() => setCurrent(d.id)}
          >
            <Row>
              <Col>
                <h2 style={{ fontSize: 22 }}>{d.title}</h2>
                <p style={{ fontSize: 18 }}>
                  {formatStringExtract(
                    d.decisionText.replaceAll(htmlRegex, ""),
                    2
                  )}
                </p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <p
                  className="mt-1 mb-2"
                  style={{ textAlign: "end", fontSize: 16 }}
                >
                  Category: {d.category.name}
                </p>
                <p
                  className="mt-1 mb-2"
                  style={{ textAlign: "end", fontSize: 16 }}
                >
                  Matter: {d.matter.name}
                </p>
                <p
                  className="mt-1 mb-2"
                  style={{ textAlign: "end", fontSize: 16 }}
                >
                  Upper decision: {d.upperDecisionID}
                </p>
              </Col>
            </Row>
          </div>
        </Row>
      ))}
    </Col>
  );
}

export default DecisionListContainer;
