import React from 'react'
import { Col, Row, Tab, Nav } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import DecisionsByCategory from '../subunits/trace/DecisionsByCategory'
import DecisionsByMatter from '../subunits/trace/DecisionsByMatter'
import Meetings from '../subunits/trace/Meetings'
import Deadlines from '../subunits/trace/Deadlines'
import Reccurences from '../subunits/trace/Reccurences'
import TraceTimeline from '../subunits/trace/Timeline'

function TraceTabsView({decisions, units, meetings, matters, reccurences, categories, setCurrentDecision}){

    const history = useHistory()

    const viewDecision = (id) => {
        setCurrentDecision(id)
        history.push('/decisions')
    }

    return (
        <Tab.Container defaultActiveKey="first">
            <Row id="traceTabs" >
                <Col sm={2} style={{paddingRight:'0'}} >
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="first" >Decisions by category</Nav.Link>
                        </Nav.Item>
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="second" >Decisions by matter</Nav.Link>
                        </Nav.Item>
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="third" >Meetings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="fourth" >Deadlines</Nav.Link>
                        </Nav.Item>
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="fifth" >Reccurences</Nav.Link>
                        </Nav.Item>
                        <Nav.Item id="tabTitle" className="traceTabs" >
                            <Nav.Link eventKey="sixth" >Timeline</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content id="traceTabContentWrapper" >
                        <Tab.Pane eventKey="first" id="traceTabContent" >
                            <DecisionsByCategory 
                                decisions={decisions} 
                                categories={categories} 
                                viewDecision={viewDecision}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <DecisionsByMatter 
                                matters={matters} 
                                decisions={decisions}
                                viewDecision={viewDecision}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <Meetings meetings={meetings} units={units} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <Deadlines 
                                decisions={decisions}
                                viewDecision={viewDecision} 
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                            <Reccurences
                                reccurences={reccurences}
                                decisions={decisions}
                                viewDecision={viewDecision}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="sixth">
                            <TraceTimeline
                                decisions={decisions}
                                units={units}
                                reccurences={reccurences}
                                meetings={meetings}
                                viewDecision={viewDecision}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export default TraceTabsView

