import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoMdOpen } from 'react-icons/io'

function RelatedSubunit({decisions, current, setCurrent}) {

    const [filteredDecisions, setFilteredDecisions] = React.useState([])
    const [filter, setFilter] = React.useState('CATEGORY')

    useEffect(() => {
        // start off with category-filter
        setFilteredDecisions(decisions.filter(d => d.category.id === current.category.id && d.id !== current.id))
    }, [current])

    const filterDec = (type) => {
        document.getElementById('relDecFiltersCategory').classList.remove('chosen')
        document.getElementById('relDecFiltersUnit').classList.remove('chosen')
        document.getElementById('relDecFiltersMatter').classList.remove('chosen')
        document.getElementById('relDecFilters' + type).classList.add('chosen')
        setFilter(type.toUpperCase())
        if(type === 'Category') {
            setFilteredDecisions(decisions.filter(d => current.category.id !== null && d.category.id === current.category.id && d.id !== current.id))
        } else if(type === 'Unit'){
            setFilteredDecisions(decisions.filter(d => current.ownerUnit.id !== null && d.ownerUnit.id === current.ownerUnit.id && d.id !== current.id))
        } else if(type === 'Matter'){
            setFilteredDecisions(decisions.filter(d => current.matter.id !== null && d.matter.id === current.matter.id && d.id !== current.id))
        }
    }

    const createDecisionRow = (dec) => {
        return (
            <Row id="tabContentTableRow" key={dec.id} >
                <Col md={2}>
                    {filter === 'CATEGORY' ? dec.category.name : (filter === 'UNIT' ? dec.ownerUnit.name : dec.matter.name)}
                </Col>
                <Col md={4}>
                    {dec.title}
                </Col>
                <Col md={2}>
                    {dec.decisionDate}
                </Col>
                <Col md={{span:1, offset:3}}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'20px', height:'20px'}} onClick={() => setCurrent(dec.id)} />
                </Col>
            </Row>
        )
    }

    return (
        <>
        <Row style={{margin:0}}>
            <Col md={12}>
                <p style={{marginTop:'20px'}} >
                    View decisions related by 
                    <b id="relDecFiltersCategory" className="relDecFilters chosen" onClick={() => filterDec('Category')} >Category</b> 
                    <b id="relDecFiltersUnit" className="relDecFilters" onClick={() => filterDec('Unit')} >Unit</b> 
                    <b id="relDecFiltersMatter" className="relDecFilters" onClick={() => filterDec('Matter')} >Matter</b>
                </p>
            </Col>
        </Row>
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={2}>
                        {filter}
                    </Col>
                    <Col md={4}>
                        ISSUE
                    </Col>
                    <Col md={2}>
                        DATE
                    </Col>
                    <Col md={{span:1, offset:3}}>
                        VIEW
                    </Col>
                </Row>
                <div id="tabContentTable" >
                    {filteredDecisions.map(fd => createDecisionRow(fd))}
                </div>
            </Col>
        </Row>
        </>
    )
}

export default RelatedSubunit