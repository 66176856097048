import React from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import AddPeopleModal from '../../modals/AddPeopleModal'
import { getDateString } from '../../../functions/dateTime'


function ReceiversSubunitView({edit, people, receivers, receiverNotes, save, updateNote}) {
    
    const [showAdd, setShowAdd] = React.useState(false)

    const createRecRow = (r) => {
        const person = people.filter(p => p.id === r)[0]
        return (
            <Row id="tabContentTableRow" key={r} >
                <Col md={3}>
                    {person.firstname} {person.lastname}
                </Col>
                <Col md={2}>
                    {/** TODO this gets a new date every time */}
                    {getDateString()}
                </Col>
                <Col md={1}>
                    {/** TODO what is this? */}
                    No response
                </Col>
                <Col md={5}>
                    <Form.Control 
                        size="sm"
                        type="text"
                        id={"note"+r} 
                        defaultValue={receiverNotes[r] && receiverNotes[r].note}
                        style={{width:'100%'}} 
                        onKeyDown={e=>e.key === 'Enter' ? document.getElementById('note'+r).blur():NaN} 
                        onBlur={e => updateNote(r, e.target.value)} 
                    />
                </Col>
            </Row>
        )
    }

    return (
        <Row style={{margin:0}} >
            <Col md={12}>
                <Row id="tabContentHeader" >
                    <Col md={3}>
                        NAME
                    </Col>
                    <Col md={2}>
                        ASSIGN DATE
                    </Col>
                    <Col md={1}>
                        RESPONSE
                    </Col>
                    <Col md={5}>
                        NOTE
                    </Col>
                    {edit ? (
                        <Col md={1} style={{paddingLeft:'30px'}} >
                            <FiEdit id="subunitAddBtn" style={{height:'18px', width:'18px'}} onClick={() => setShowAdd(true)} />
                        </Col>
                    ) : ''}
                </Row>
                <div id="tabContentTable">
                    {receivers && receivers.map(r => createRecRow(r))}
                </div>
            </Col>
            <AddPeopleModal 
                show={showAdd} 
                onHide={() => setShowAdd(false)} 
                title="receivers" 
                saveFunc={(recArr) => {save(recArr); setShowAdd(false)}} 
                people={people}
                receivers={receivers}
            />
        </Row>
    )

}

export default ReceiversSubunitView