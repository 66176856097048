export function unitJson(decisions, units, current, people, unitMembers) {

    const getUnitDecisions = () => {
        var arr = []
        const unitDec = decisions.filter(d => d.ownerUnit.id === current.id)
        unitDec.forEach(ud => 
            arr.push({
                name: ud.title,
                attributes: {
                    type: 'decision',
                    id: ud.id
                }
            })
        )
        return arr.length === 0 ? undefined : arr
    }

    const unitDecisions = getUnitDecisions()

    const uppUnitChildren = !current.superUnitID ? undefined
        : [
            {
                name: units.filter(u => u.id === current.superUnitID)[0].name,
                attributes: {
                    type: 'unit',
                    id: current.superUnitID
                }
            }
        ]
    
    const getSubUnits = () => {
        var arr = []
        const filtered = units.filter(u => u.superUnitID === current.id)
        filtered.forEach(f => {
            arr.push({
                name: f.name,
                attributes: {
                    type: 'unit',
                    id: f.id
                }
            })
        })

        return arr.length === 0 ? undefined : arr
    }

    const subUnitChildren = getSubUnits()

    const getMembers = () => {
        var arr = []
        const filteredMembers = unitMembers.filter(um => um.unitID === current.id)
        filteredMembers.forEach(fm => {
            const person = people.filter(p => p.id === fm.personID)[0]
            if(person){
                arr.push({
                    name: person.firstname + ' ' + person.lastname
                })
            }
        })
        return arr.length === 0 ? undefined : arr
    }

    const members = getMembers()

    const unitData = {
        name: current.name,
        children: [
            {
                name: 'Members',
                children: members
            },
            {
                name: 'Upper unit',
                children: uppUnitChildren
            },
            {
                name: 'Sub-units',
                children: subUnitChildren
            },
            {
                name: 'Meetings',
                children: [
                    {
                        name: 'TODO',
                        attributes: {
                            type: 'meeting'
                        }
                    }
                ],
            },
            {
                name: 'Matter'
            },
            {
                name: 'Decisions',
                children: unitDecisions  
            }
      ]
    }

    return unitData
}