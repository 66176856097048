import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoMdOpen } from 'react-icons/io'

function Deadlines({decisions, viewDecision }) {

    const htmlRegex = /<[a-z/]*>/g

    const sortDecisions = () => {
        const decTmp = decisions
        decTmp.sort((a, b) => {return new Date(b.validTo) - new Date(a.validTo)})

        return decTmp.map(d => createDeadlineRow(d))
    }

    const createDeadlineRow = (dec) => {
        return (
            <Row key={dec.id} id="tabContentTableRow">
                <Col md={4}>
                    {(dec.title.length >= 100) ? dec.title.substring(0,100) + '...' : dec.title}
                </Col>
                <Col md={5}>
                    <div dangerouslySetInnerHTML={{__html:(dec.decisionText.length > 100 ? dec.decisionText.replaceAll(htmlRegex,'').substring(0,100)+'...' : dec.decisionText.replaceAll(htmlRegex,''))}}/>
                </Col>
                <Col md={2}>
                    {dec.validTo ? dec.validTo : 'No deadline'}
                </Col>
                <Col md={1}>
                    <IoMdOpen id="subunitAddBtn" style={{width:'18px', height:'18px'}} onClick={() => viewDecision(dec.id)} />
                </Col>

            </Row>
        )
    }

    return (
        <>
        <Row id="tabContentHeader" >
            <Col md={4}>
                ISSUE
            </Col>
            <Col md={5}>
                DECISION
            </Col>
            <Col md={2}>
                DEADLINE
            </Col>
            <Col md={1}>
                VIEW
            </Col>
        </Row>
        <div>
            {sortDecisions()}
        </div>
        
        </>
    )

}

export default Deadlines