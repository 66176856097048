import React from 'react'
import { Row, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Tree from 'react-d3-tree'
import { BsLayoutTextWindow } from 'react-icons/bs'
import { decisionJson } from '../treeDataFunctions/decisionJson'
import { unitJson } from '../treeDataFunctions/unitJson'
import { categoryJson } from '../treeDataFunctions/categoryJson'
import { getDecisionData, setCurrentDecision, setCurrentUnit, updateDecision, getNotes, setNotes, removeNote } from '../../redux/actions/data'

function TreeView({type, decisions, units, categories, currDec, currUnit, people, unitMembers, setCurrentDecision, setCurrentUnit, loading}) {

    const history = useHistory()

    // Get type from history stack
    const histType = history.location.state ? history.location.state.type : ''
    const [treeType, setTreeType] = React.useState(type ? type : histType)

    const header = treeType === ('decision' && currDec) ? currDec.title : (treeType === 'unit' && currUnit) ? currUnit.name : 'TREE'
    
    const data = (treeType === 'decision' && currDec )
      ? decisionJson(decisions, units, currDec)
      : (treeType === 'unit' && currUnit )
        ? unitJson(decisions, units, currUnit, people, unitMembers)
        : treeType === 'category'
          // TODO static category choice
          ? categoryJson(decisions, units, categories[13])
          : {}

    const changeCurrent = (type, id) => {
      setTreeType(type)
      if(type === 'decision') {
        setCurrentDecision(id)
      }
      else if(type === 'unit') setCurrentUnit(id)

    }

    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps
      }) => (
        <g>
          <circle id={nodeDatum.children ? "treeCircle" : ''} r={15} onClick={toggleNode} ></circle>
          <foreignObject {...foreignObjectProps}>
                {nodeDatum.children || !nodeDatum.attributes ? (
                    <p id="treeBranch" onClick={toggleNode} >
                      {nodeDatum.name} <br/>
                      {(currDec && nodeDatum.name === currDec.title) || (currUnit && nodeDatum.name === currUnit.name )
                        ? <></> 
                        : <p style={{fontSize:'0.8rem', color:'gray'}} >{nodeDatum.children ? 'Click to view' : 'Nothing to show'}</p>
                      }
                    </p>
                ): (
                    <p id="treeLeaf" onClick={() => changeCurrent(nodeDatum.attributes.type, nodeDatum.attributes.id)} >
                        {nodeDatum.name}
                    </p>
                )}
            
          </foreignObject>
        </g>
      )

    const foreignObjectProps = {width: 175, height: 150}

    return (
      <Col id="mainviewcol">
        {(!treeType || loading || !decisions || (!currDec && !currUnit))
        ? ( <Col md={12} className="d-flex justify-content-center" style={{paddingTop:'5%'}}>
                <Spinner animation="border" />
            </Col>
        )
        : (
          <>
          <Row style={{height:'3rem', backgroundColor:'lightgrey'}}>
                    <Col md={7} style={{fontSize:'1.7rem', paddingTop:'0.3rem'}} >{header}</Col>
                    <Col md={{span:1, offset:4}}>
                        <OverlayTrigger placement="left" overlay={<Tooltip style={{fontSize:'12px'}} >View details</Tooltip>} > 
                            <BsLayoutTextWindow id="decisionIcons" onClick={() => history.push('/' + treeType + 's')} />
                        </OverlayTrigger> 
                    </Col>
                </Row>
          <div id="treeWrapper" style={{ width: '100%', height: '75vh' }}>
            <Tree 
                data={data} 
                translate={{x:window.innerWidth/2-100, y:100}}
                orientation="vertical"
                nodeSize={{x:200, y:200}}
                initialDepth={1}
                pathFunc={"step"}
                scaleExtent={{min: 0.5, max: 3}}
                renderCustomNodeElement={(rd3tProps) => 
                    renderForeignObjectNode({...rd3tProps, foreignObjectProps})
                }
                
            />
          </div>
          </>
        )}
      </Col>
    )


}

export default connect(
  state => { return {
      decisions: state.data.decisions,
      currDec: state.data.currentDecision,
      currUnit: state.data.currentUnit,
      categories: state.data.categories,
      units: state.data.units,
      people: state.data.people,
      unitMembers: state.data.unitMembers,
      loading: state.data.loading
  }},
  dispatch => { return {
      setCurrentDecision: (id) => dispatch(setCurrentDecision(id)),
      setCurrentUnit: (id) => dispatch(setCurrentUnit(id)),
      updateDecision: (data) => dispatch(updateDecision(data)),
      getDecisionData: () => dispatch(getDecisionData()),
      getNotes: (id) => dispatch(getNotes(id)),
      setNotes: (id, data) => dispatch(setNotes(id, data)),
      removeNote: (decID, noteID) => dispatch(removeNote(decID, noteID))
  }}
)(TreeView)