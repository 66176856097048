import React from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { GrAddCircle } from "react-icons/gr";
import { ImCross, ImArrowUp2, ImArrowDown2 } from "react-icons/im";
import logo from "../../img/logoNoBackground.png";
import { getActionTitle } from "../../functions/directionActionTitle";

function DirectionEditModal({ direction, currentDec, show, onHide }) {
  const [stages, setStages] = React.useState(direction ? direction.stages : []);
  const [name, setName] = React.useState(direction ? direction.name : "");
  const [date, setDate] = React.useState(direction ? direction.date : "");

  const changeName = (index, nn) => {
    var tmp = stages;
    tmp[index].name = nn;
    return tmp;
  };

  const createStageRow = (stage, index) => {
    return (
      <div key={index}>
        <Row id={"stageRow" + index} className="stageRows">
          <Col md={3}>
            <Form.Control
              type="text"
              size="sm"
              style={{ width: "100%" }}
              value={stage.name}
              onChange={(e) =>
                setStages([...changeName(index, e.target.value)])
              }
            />
          </Col>
          <Col md={4}>
            <Form.Control
              as="textarea"
              rows="1"
              size="sm"
              style={{ width: "100%" }}
              id="stageRowDescription"
              value={stage.description}
              onChange={(e) => {
                stages[index].description = e.target.value;
                stages[index].action.condition = "";
                setStages([...stages]);
              }}
            />
          </Col>
          <Col md={3}>
            <Form.Control
              type="text"
              size="sm"
              style={{
                width: "100%",
                backgroundColor: "white",
                cursor: "pointer",
              }}
              readOnly
              defaultValue={getActionTitle(stages, index)}
              onClick={() => {
                document
                  .getElementById("stageActionRow" + index)
                  .classList.toggle("actionHidden");
                document
                  .getElementById("stageRow" + index)
                  .classList.toggle("actionActive");
              }}
            />
          </Col>
          <Col md={1}>
            {index !== 0 && (
              <ImArrowUp2
                className="stageRowBtns"
                onClick={() => {
                  stages.splice(index - 1, 0, stages.splice(index, 1)[0]);
                  setStages([...stages]);
                }}
              />
            )}
            {index !== stages.length - 1 && (
              <ImArrowDown2
                className="stageRowBtns"
                onClick={() => {
                  stages.splice(index, 0, stages.splice(index + 1, 1)[0]);
                  setStages([...stages]);
                }}
              />
            )}
          </Col>
          <Col md={1}>
            <ImCross
              className="stageRowBtns"
              onClick={() => setStages(stages.filter((s, i) => i !== index))}
            />
          </Col>
        </Row>
        {/** Action edit, hidden unless action field is clicked */}
        {stage.action ? (
          <Row id={"stageActionRow" + index} className="actionHidden actionRow">
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={3}>Email to</Col>
                    <Col md={9}>
                      <Form.Control
                        type="email"
                        size="sm"
                        style={{ width: "100%" }}
                        value={stage.action.emailTo ? stage.action.emailTo : ""}
                        onChange={(e) => {
                          stages[index].action.emailTo =
                            e.target.value === "" ? null : e.target.value;
                          setStages([...stages]);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Document</Col>
                    <Col md={7} style={{ paddingRight: 0 }}>
                      <Form.File
                        label={
                          stage.action.document
                            ? stage.action.document
                            : "Choose a file"
                        }
                        custom
                        onChange={(e) => {
                          const file = e.target.files[0];
                          stages[index].action.document = file
                            ? file.name
                            : null;
                          setStages([...stages]);
                        }}
                      />
                    </Col>
                    <Col md={2} style={{ paddingLeft: 0 }}>
                      <Button
                        style={{
                          backgroundColor: "#e9ecef",
                          color: "#495057",
                          border: "1px solid #ced4da",
                          width: "100%",
                        }}
                        onClick={() => {
                          //stages[index].action.document = null;
                          setStages([...stages]);
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Approval from</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        size="sm"
                        style={{ width: "100%" }}
                        value={
                          stage.action.approval ? stage.action.approval : ""
                        }
                        onChange={(e) => {
                          stages[index].action.approval =
                            e.target.value === "None" || e.target.value === ""
                              ? null
                              : e.target.value;
                          setStages([...stages]);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={2}>If</Col>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        size="sm"
                        style={{ width: "100%" }}
                        value={stage.action.condition}
                        placeholder="Condition"
                        onChange={(e) => {
                          stages[index].action.condition =
                            e.target.value === "None" || e.target.value === ""
                              ? "null"
                              : e.target.value;
                          setStages([...stages]);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>Go to</Col>
                    <Col md={10}>
                      <Form.Control
                        as="select"
                        size="sm"
                        style={{ width: "100%" }}
                        value={stage.action.stageJump}
                        onChange={(e) => {
                          stages[index].action.stageJump =
                            e.target.value === "None" || e.target.value === ""
                              ? null
                              : e.target.value;
                          setStages([...stages]);
                        }}
                      >
                        <option>None</option>
                        {stages.map((s, i) => {
                          if (i !== index) return <option key={i}>{i}</option>;
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <> </>
        )}
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide({ date: date, name: name, stages: stages })}
      centered
      dialogClassName="directionEditModal"
    >
      <Modal.Header
        closeButton
        style={{ paddingTop: "7px", paddingBottom: "2px" }}
      >
        <Modal.Title style={{ width: "100%" }}>
          <Row>
            <Col md={1}>
              <img src={logo} alt="BasedOn" width="60" />
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="directionEditModalBody">
        <div>
          <Row style={{ paddingBottom: "10px" }}>
            <Col md={{ offset: 3, span: 2 }}>Decision</Col>
            <Col md={4}>{currentDec}</Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col md={{ offset: 3, span: 2 }}>Name</Col>
            <Col md={4}>
              <Form.Control
                size="sm"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={{ offset: 3, span: 2 }}>Date</Col>
            <Col md={4}>
              <Form.Control
                size="sm"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div id="directionStageHeaders">
          <Row>
            <Col md={3}>Stage</Col>
            <Col md={4}>Description</Col>
            <Col md={4}>Actions</Col>
            <Col md={1}>
              <GrAddCircle
                id="directionAddStage"
                onClick={() =>
                  setStages([
                    ...stages,
                    { name: "", description: "", action: {} },
                  ])
                }
              />
            </Col>
          </Row>
        </div>
        <div id="directionStageRows">
          {stages.map((s, i) => createStageRow(s, i))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DirectionEditModal;
