import React from 'react'
import { Col, Row } from 'react-bootstrap'

function Meetings({meetings, units}) {

    const createMeetingData = () => {
        var meetingArr = []
        // For sorting purposes
        Object.keys(meetings).forEach(key => {
            Object.keys(meetings[key]).forEach(m => {
                meetingArr.push({
                    id: m,
                    title: meetings[key][m].title,
                    location: meetings[key][m].location,
                    dateTime: meetings[key][m].dateTime,
                    duration: meetings[key][m].duration,
                    unit: key
                })
            }
            )
        })
        meetingArr = meetingArr.sort((a,b) => {return new Date(a.dateTime) - new Date(b.dateTime)})
        return meetingArr.map(m => createMeetingRow(m))
    }

    const createMeetingRow = (m) => {
        return (
            <Row key={m.id} id="tabContentTableRow">
                <Col md={2}>
                    {units.filter(u => u.id === m.unit)[0].name}
                </Col>
                <Col md={3}>
                    {m.title}
                </Col>
                <Col md={3}>
                    {m.location}
                </Col>
                <Col md={2}>
                    {m.dateTime}
                </Col>
                <Col md={2}>
                    {m.duration}
                </Col>
            </Row>
        )
    }

    return (
        <>
        <Row id="tabContentHeader" >
            <Col md={2}>
                UNIT
            </Col>
            <Col md={3}>
                TITLE
            </Col>
            <Col md={3}>
                LOCATION
            </Col>
            <Col md={2}>
                DATE AND TIME
            </Col>
            <Col md={2}>
                DURATION
            </Col>
        </Row>
        {createMeetingData()}
        </>
    )

}

export default Meetings