import React from 'react'
import { Modal, Button } from "react-bootstrap";

const WarningModal = ({show, onHide, message, btn1Msg, btn1Func, btn2Msg, btn2Func}) => {

    return (
        <Modal
            show={show} 
            onHide={onHide}>
            <Modal.Body >
                {message}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-secondary" onClick={btn1Func}>
                {btn1Msg}
            </Button>
            <Button variant="secondary" onClick={btn2Func}>
                {btn2Msg}
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default WarningModal