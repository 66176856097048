import React from 'react'
import { Col, Row } from 'react-bootstrap'
import comingsoon from '../../img/comingsoon.png'

function MeetingContainer() {
    return (
        <Col id="mainviewcol" >
            <>
            <Row style={{height:'3rem', backgroundColor:'lightgrey'}}>
                <Col md={2} style={{fontSize:'1.8rem'}} >MEETINGS</Col>
            </Row>
            <Col md={{offset:4, span:6}}>
                <img src={comingsoon} alt="coming soon"/>
            </Col>
            </>
            
        </Col>
    )
}

export default MeetingContainer